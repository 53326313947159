import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import styled, { css } from "styled-components";

import { Map } from "immutable";
import ls from "local-storage";
import TimeAgo from "javascript-time-ago";

import sleep_ok from "../../../assets/images/sleep_2.svg";
import ios_icon from "../../../assets/images/badges/apple.svg";
import android_icon from "../../../assets/images/badges/android.svg";

import footprint_ok from "../../../assets/images/badges/footprint_ok.svg";
import footprint_danger from "../../../assets/images/badges/footprint_ok.svg";

import alarm_img from "../../../assets/images/alarm.svg";
import unlink_img from "../../../assets/images/unlink.svg";
import num_img from "../../../assets/images/number.svg";
import qrcode_img from "../../../assets/images/qr-code.svg";
import spot_iimg from "../../../assets/images/spot_edit.svg";
import bell_1 from "../../../assets/images/bell_1.svg";
import "./style.css";
import en from "javascript-time-ago/locale/en";
import UiHelper from "../../../helpers/UiHelper";
import { isDebugMode } from "../../../helpers/UiHelper";
import NewsScoreHelper, {
  ConsciousnessLevels,
} from "../../../helpers/NewsScoreHelper";
import SelectBraceletTool from "../../bracelets/tools/SelectBraceletTool";
import moment from "moment";
import BatterySpan from "./BatterySpan";
import { getLabelsInfoData } from "../../groups/panels/GroupsPanel";
import {
  getOptionsByTypesAndValues,
  NEWS_NO_PR,
  NEWS_PR1,
  NEWS_PR2,
  NEWS_PR3,
  PR_1,
  PR_2,
  PR_3,
  NO_PRIORITY,
} from "../../ranges/new_tools/NewRangeTool";

import sound from "../../../assets/sounds/HP-failure.wav";

import ok_cloud_image from "../../../assets/images/ok_cloud_1.png";
import bad_cloud_image from "../../../assets/images/bad_cloud.png";

import Select from "react-select";
import * as UiActions from "../../../redux/actions/UiActions";

import { useInterval } from "../../apps/RouterApp";
import NiceModal from "../../modals/NiceModal";
import Spin from "arui-feather/spin";
import AgoSpan from "./AgoSpan";
import ComplianceSpan from "./ComplianceSpan";
import Bell from "./Bell";
import CuffBpSpan from "./CuffBpSpan";
import QRCodeSpan from "./QRCodeSpan";
import SpotMeasurementsTool from "./SpotMeasurementsTool";
import {
  DEFAULT_LOC,
  DEFAULT_SO,
} from "../../ranges/new_tools/PatientOtherNewsTool";
import renderNews2 from "./renderNews2";
import renderParam from "./renderParam";
import renderParamEnhanced2 from "./renderParamEnhanced2";
import renderNewsOnTiles from "./renderNewsOnTiles";
import {
  NameSectionPlaceholderBigTiles,
  normalBorder,
  PatientItemBottomBigTiles,
  ParamsItemsPlaceholderBigTiles,
  PatientItemTopSmallTiles,
  NameSectionPlaceholderSmallTiles,
  ParamsItemsPlaceholderSmallTiles,
  PatientItemBottomSmallTiles,
  BottomItemSmallTiles,
} from "./ui/ParamElements";

const PAGE_SIZE_OPTIONS = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

const AF_PAGE_SIZE_OPTIONS = [
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
];

try {
  TimeAgo.addDefaultLocale(en);
} catch (exc) {}

const DANGER_TIMEOUT = 60 * 60 * 1000;

const timeAgo = new TimeAgo("en-US");

const getSleepString = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;
  hours = `${hours}`.padStart(2, "0");
  minutes = `${minutes}`.padStart(2, "0");
  return `${hours}:${minutes}`;
};

function occurrences(string, subString, allowOverlapping) {
  string += "";
  subString += "";
  if (subString.length <= 0) return string.length + 1;

  var n = 0,
    pos = 0,
    step = allowOverlapping ? 1 : subString.length;

  while (true) {
    pos = string.indexOf(subString, pos);
    if (pos >= 0) {
      ++n;
      pos += step;
    } else break;
  }
  return n;
}

const isParamInDanger = (paramName, paramValue, xRanges) => {
  // console.log('isParamInDanger: paramName, paramValue, xRanges = ', paramName, paramValue, xRanges);
  let v = (xRanges || {})[paramName];
  if (v == undefined) {
    return false;
  }
  let { min, max } = v;
  return +paramValue > +max || +paramName < +min;
};

function getCleanName(s, code) {
  let s2 = `${s}`;
  if (occurrences(s2, code) > 1) {
    s2 = s2.replace(`[${code}]`, "");
  }
  return s2;
}

let shouldPlaySound = (illPatients, silentItems = [], alarmsEnabled) => {
  let f = false;
  if (
    alarmsEnabled == false ||
    window.location.href.indexOf("cardiomood") > -1
  ) {
    return false;
  }
  if (window.location.href.indexOf("study.corsano.com") > -1) {
    return false;
  }
  // if (window.location.href.indexOf('portal') == -1){
  //     return false;
  // }
  for (let i in illPatients) {
    let p = illPatients[i];
    if (silentItems.map((x) => x.uuid).indexOf(p.uuid) == -1) {
      f = true;
    }
  }
  return f;
};

export default function PatientsListTool(props) {
  const {
    tag = "test",
    patients = [],
    hiddenPatientsIds = [],
    sortByName = true,
    order = "default",
    filter = "none",
    lastMetricsLoading = false,
    groupCompliance = {},
    groupCuffBp = {},
    groupSpotMeasurements = {},
    onPatientClick = (pp) => {},
    onPatientAlarmClick = (pp) => {},
    onPatientSpotMeasurementsClick = (pp) => {},
    onBraceletChanged = (pUUID) => {},
    onUnlinkClick = (pp) => {},
    onHide = (pp) => {},
    onUnhide = (pp) => {},
    onEnlargeQRCode = () => {},
    patientsBraceletsMap = Map(),
    devicesMap = {},
    metricsMap = {},
    isDemo = false,
    getUserRanges = (uuid) => {},
    realMetricsMap = {},
    alarmsEnabled = false,
    hasInternet = true,
  } = props;

  // console.log("patients", patients);
  
  const [pageSelectorOpen, setPageSelectorOpen] = useState(false);
  const { alarm, unit, selectedPriority, role, alarmParams } = useMappedState(
    useCallback((state) => {
      return {
        alarm: state.ui.alarm,
        // alarm: "flags",
        // alarm: "none",
        // alarm: "news",
        unit: state.ui.unit,
        selectedPriority: state.ui.selectedPriority,
        role: state.ui.role,
        alarmParams: state.ui.alarmParams,
      };
    })
  );

  // TODO: fix unselected alarm params falsely on
  // console.log("alarms_settings", alarmParams);

  const dispatch = useDispatch();
  const isHCPAdmin = role === "hcp-admin";

  // console.log("current alarm", alarm);

  const StatusType = alarm.includes("news")
    ? "News2"
    : alarm.includes("flag")
    ? "flag"
    : "corrisk";
  // console.log("ALARM TYPE", alarm);

  let isAf = window.location.href.indexOf("af.corsano") > -1;
  let isPortal = window.location.href.indexOf("portal") > -1;

  const layout = isAf ? "normal" : ls.get("layout") || "normal";

  const [pageSize, setPageSize] = useState(
    // 25
    ls("corsano_patients_page_size") || 25
    // isAf ? 25 : ls("corsano_patients_page_size") || 10
    // ls("corsano_patients_page_size") || layout === "small_tiles" ? 12 : 10
  );
  const maxPageNum = patients.length / pageSize;

  const [page, setPage] = useState(ls.get("current-page") || 0);

  // const [selectedPriority, setSelectedPriority] = useState(-1);
  const [selectedPatients, setSelectedPatients] = useState(patients || []);

  useEffect(() => {
    // console.log("render2222");
    if (pageSize != undefined) {
      ls("corsano_patients_page_size", pageSize);
      let currentPage = ls.get("current-page") || 0;
      if (currentPage > maxPageNum) {
        currentPage = 0;
      }
      setPage(currentPage);
    }
  }, [pageSize]);

  useEffect(() => {
    // setPage(0);
    // console.log("render1111");

    let currentPage = ls.get("current-page") || 0;
    if (currentPage > maxPageNum) {
      currentPage = 0;
    }
    setPage(currentPage);
  }, [(patients || []).length]);

  useEffect(() => {
    setSelectedPatients(
      patients.filter((x) => {
        if (selectedPriority === -1) {
          return true;
        }
        return x.maxPriority === selectedPriority;
      })
    );
    // }, [(patients || []).length]);
  }, [patients || []]);

  const pageSelector = useMemo(() => {
    // console.log("PAGE SLT RERENDER");
    return (
      <PageSelector SetPageSize={setPageSize} isAF={isAf} pageSize={pageSize} />
    );
  }, []);

  let old_metricsMap = metricsMap;
  const audioRef = useRef(new Audio(sound));
  const [actModalTimestamp, setActModalTimestamp] = useState(undefined);

  if (realMetricsMap == undefined || Object.keys(realMetricsMap).length == 0) {
    old_metricsMap = {};
  }

  // console.log("PatientsList: patients = ", patients);
  // console.log("PatientsList: old_metricsMap = ", old_metricsMap);
  // console.log("PatientsList: realMetricsMap = ", realMetricsMap);
  // console.log("old_metricsMap", old_metricsMap);

  let rangeItemsMap = patients
    .map((p, i) => {
      let xRanges = getUserRanges(p.uuid);
      let realVal = realMetricsMap[p?.uuid];
      let t_sk1 = realVal?.raw_temperatures?.temp_sk1;
      let t_sk2 = realVal?.raw_temperatures?.temp_sk2;
      let temper;
      // let temper_ = p?.realtime?.temperature || t_sk1 || t_sk2;
      // if (temper_ != undefined) {
      //     if (t_sk1 != undefined && t_sk2 != undefined) {
      //         temper_ = Math.max(t_sk1, t_sk2);
      //     }
      // }
      // console.log("patient ppp", p.name, p);

      let { temp_sk1, temp_sk2, temp_sk1_timestamp, temp_sk2_timestamp } =
        p?.realtime?.latest_filled_values?.raw_temperatures || {};
      let temperature_timestamp = undefined;
      if (temp_sk1 != undefined) {
        temper = temp_sk1;
        temperature_timestamp = temp_sk1_timestamp;
        if (temp_sk2 != undefined) {
          if (+temp_sk2 > +temp_sk1) {
            temperature_timestamp = temp_sk2_timestamp;
            temper = temp_sk2;
          }
        }
      }
      // console.log("prealtime", p?.realtime);

      let arr = [
        {
          type: "spo2",
          // value: p?.realtime?.spo2 || realVal?.raw_activities?.spo2,
          value: p?.realtime?.spo2,
          ranges: xRanges["spo2"],
        },
        {
          type: "respiration_rate",
          // value: p?.realtime?.respiration_rate || realVal?.raw_activities?.respiration_rate,
          value: p?.realtime?.respiration_rate,
          ranges: xRanges["respiration_rate"],
        },
        {
          type: "heart_rate",
          // value: p?.realtime?.heart_rate || realVal?.raw_activities?.bpm,
          value: p?.realtime?.heart_rate,
          ranges: xRanges["heart_rate"],
        },
        {
          type: "temperature",
          // value: p?.realtime?.temperature || temper_,
          // value: p?.realtime?.temperature,
          value: temper,
          ranges: xRanges["temperature"],
        },
        {
          type: "blood_pressure",
          value: p?.realtime?.bp_data?.sbp,
          ranges: xRanges["blood_pressure"],
        },
      ];

      let map = getOptionsByTypesAndValues(arr);
      // console.log("MAPMAP", arr);

      let maxPriority = 0;
      let maxPriorityColor = "#D3D3D3";
      // for (let key_ in map) {
      //     let v_ = map[key_]?.priority;
      //     if (v_ != undefined && +v_ > maxPriority) {
      //         maxPriority = +v_;
      //         maxPriorityColor = map[key_]?.color;
      //     }
      // }

      let priorities = [
        alarmParams["heart_rate"] ? p?.realtime?.heart_rate_alarm_priority : 0,
        alarmParams["spo2"] ? p?.realtime?.spo2_alarm_priority : 0,
        alarmParams["respiration_rate"]
          ? p?.realtime?.respiration_rate_alarm_priority
          : 0,
        alarmParams["temperature"]
          ? p?.realtime?.temperature_alarm_priority
          : 0,
        alarmParams["systolic_blood_pressure"]
          ? p?.realtime?.blood_pressure_alarm_priority
          : 0,
      ].filter((x) => x != undefined);
      // console.log("alarmParams", alarmParams, priorities);

      // let bt = p?.realtime?.display_timestamp;
      let bt = p?.realtime?.raw_data_freshest_timestamp;
      let outdated = false;
      let outatedHours = 4;

      if (bt != undefined) {
        let hoursToNow = -moment(bt).diff(moment(), "hours");
        if (hoursToNow > outatedHours) {
          outdated = true; // patient considered outdated if inactive for more than 4 hours,
        }
        // console.log(p?.first_name, hoursToNow,  -moment(bt).diff(moment(), "minutes"), outdated);
      } else {
        outdated = true;
      }

      maxPriority = priorities.length == 0 ? 0 : Math.max(...priorities);
      // console.log("maxPriority", maxPriority, priorities);

      let _maxPriority = p?.realtime
        ? outdated
          ? NO_PRIORITY.priority
          : maxPriority
        : 0;

      maxPriorityColor = UiHelper.getColorByPriority(maxPriority);
      let _maxPriorityColor = [PR_3, PR_1, PR_2, NO_PRIORITY].find(
        (x) => x.priority === _maxPriority
      ).color;

      if (
        (!p?.first_name && !p?.last_name && !p?.realtime?.display_timestamp) ||
        hiddenPatientsIds.includes(p?.uuid)
      ) {
        _maxPriority = 0;
        _maxPriorityColor = "";
      }

      // console.log(p);
      // heart_rate_alarm_priority
      // respiration_rate_alarm_priority
      // spo2_alarm_priority
      // temperature_alarm_priority
      // no blood pressure

      // NewsScoreHelper.getRespirationNewsScore()

      let d = {
        id: p.uuid,
        ...map,
        maxPriority: _maxPriority,
        maxPriorityColor: _maxPriorityColor,
        outdated: outdated,
      };
      return d;
    })
    .reduce((mp, x) => ({ ...mp, [x.id]: x }), {});

  let ePatients = patients
    // let ePatients = selectedPatients
    .map((p, i) => {
      let portalAlertsMap = rangeItemsMap[p.uuid] || {};
      let {
        maxPriorityColor,
        maxPriority = 0,
        outdated,
        // newsScoreData,
      } = portalAlertsMap;
      let xName = getCleanName(
        `${p.name}${p.code == undefined ? "" : ``}`,
        p.code
      );
      let compliance = groupCompliance[p.uuid];

      // const alarmsPrioritiesMap = {
      //     pulse_rate: p?.realtime?.heart_rate_alarm_priority,
      //     respiration_rate: p?.realtime?.respiration_rate_alarm_priority,
      //     spo2: p?.realtime?.spo2_alarm_priority,
      //     temperature: p?.realtime?.temperature_alarm_priority,
      //     blood_pressure: p?.realtime?.blood_pressure_alarm_priority,
      // };

      // let newsScoreData =
      // NewsScoreHelper.getNewsScoreData(alarmsPrioritiesMap);

      const bp_news_score =
        p?.realtime?.blood_pressure_news_score === undefined
          ? "-"
          : p?.realtime?.blood_pressure_news_score;
      const pulse_rate_news_score = p?.realtime?.heart_rate_news_score;
      const spo2_news_score = p?.realtime?.spo2_news_score;
      const temperature_news_score = p?.realtime?.temperature_news_score;
      const respiration_rate_news_score =
        p?.realtime?.respiration_rate_news_score;

      const userSpots = groupSpotMeasurements[p.uuid];
      const consciousness =
        userSpots?.external_device_spot_measurement_consciousness_level
          ?.consciousness_level || DEFAULT_LOC;
      const onOxygen =
        userSpots?.external_device_spot_measurement_supplemental_oxygen
          ?.supplemental_oxygen || DEFAULT_SO;

      const onOxygen_score = onOxygen === 2 ? 0 : 2;

      // console.log(xName, onOxygen, onOxygen_score);

      const consciousness_score = ConsciousnessLevels[consciousness]?.point;

      const _params = [
        bp_news_score,
        pulse_rate_news_score,
        spo2_news_score,
        temperature_news_score,
        respiration_rate_news_score,
        onOxygen_score,
        consciousness_score,
      ];
      const [level, note] = NewsScoreHelper.processScore(_params);

      let newsScoreData = {
        blood_pressure: bp_news_score,
        pulse_rate: pulse_rate_news_score,
        spo2: spo2_news_score,
        temperature: temperature_news_score,
        respiration_rate: respiration_rate_news_score,

        onOxygen: onOxygen_score,
        consciousness: consciousness_score,

        pulse_rate_color: p?.realtime?.heart_rate_news_score_color,
        spo2_color: p?.realtime?.spo2_news_score_color,
        respiration_rate_color: p?.realtime?.respiration_rate_news_score_color,
        blood_pressure_color: p?.realtime?.blood_pressure_news_score_color,
        temperature_color: p?.realtime?.temperature_news_score_color,

        onOxygen_color: NewsScoreHelper.getColorByScore(onOxygen_score),
        consciousness_color: ConsciousnessLevels[consciousness].color,

        spo2_value: p?.realtime?.spo2
          ? p?.realtime?.spo2.toFixed(0)
          : undefined,
        pulse_rate_value: p?.realtime?.heart_rate
          ? p?.realtime?.heart_rate.toFixed(0)
          : undefined,
        respiration_rate_value: p?.realtime?.respiration_rate
          ? p?.realtime?.respiration_rate.toFixed(0)
          : undefined,
        temperature_value: p?.realtime?.latest_filled_values?.raw_temperatures
          ?.temp_sk1
          ? p?.realtime?.latest_filled_values?.raw_temperatures?.temp_sk1.toFixed(
              1
            )
          : undefined,
        blood_pressure_value: p?.realtime?.bp_data?.sbp
          ? p?.realtime?.bp_data?.sbp.toFixed(0)
          : "-",
        onOxygen_value: onOxygen === 2 ? "Air" : "Oxygen",
        consciousness_value: consciousness,

        // combined levels
        // total: p?.realtime?.total_news_score,
        total: _params.filter((x) => !isNaN(x)).reduce((s, a) => s + a, 0),
        // total_color: p?.realtime?.total_news_score_color,
        total_color: NewsScoreHelper.getColorByScore(level),
        level: level,
        note: note,
      };

      return {
        ...p,
        maxPriority: maxPriority,
        xName: xName,
        newsScoreData: newsScoreData,
        compliance: compliance,
        outdated: outdated,
      };
    })
    // .sort((a, b) => {
    //   if (sortByName) {
    //     return a.xName > b.xName ? 1 : -1;
    //   }
    // })
    .sort((a, b) => {
      let onStudy = window.location.href.indexOf("study") > -1;
      let onPortal = window.location.href.indexOf("portal") > -1;
      if (onPortal) {
        return +b.maxPriority - +a.maxPriority;
      } else {
        if (order === "default") {
          return +b.maxPriority - +a.maxPriority;
        } else {
          return 0;
        }
      }
    });

  if (window.location.href.indexOf("portal") > 0) {
  } else {
    if (order === "default") {
      // do nothing
    } else if (order === "alphabetical") {
      // console.log("order === alphabetical");
      ePatients = ePatients.sort((a, b) => {
        let s1 = `${a.xName}`.toLowerCase();
        let s2 = `${b.xName}`.toLowerCase();
        if (s1 > s2) {
          return 1;
        }
        if (s1 < s2) {
          return -1;
        }
        return 0;
      });
    } else if (order === "compliance") {
      ePatients = ePatients.sort((a, b) => {
        let s1 = a?.compliance;
        let s2 = b?.compliance;

        if (s1 > s2) {
          return -1;
        }
        if (s1 < s2) {
          return 1;
        }
        return 0;
      });
    } else {
    }
  }
  // const [silentItems, setSilentItems] = useState(ls(`silentItems_${tag}`) || ePatients.filter(x => (x.maxPriority > 0)).map(x => x.uuid));
  const [silentItems, setSilentItems] = useState(
    ls(`silentItems_${tag}`) || []
  );
  let illPatients = ePatients.filter((x) => x.maxPriority > 8); // 9 and 10 priorities are with sound
  let notMutedIllPatients = illPatients.filter(
    (x) => silentItems.map((x) => x.uuid).indexOf(x.uuid) == -1
  );

  useEffect(() => {
    ls(`silentItems_${tag}`, silentItems);
    if (shouldPlaySound(illPatients, silentItems, alarmsEnabled) == false) {
      return;
    }
    try {
      // audioRef.current.play();
      UiHelper.playSoundForIllPatients(notMutedIllPatients);
    } catch (exc) {}
  }, [silentItems, hasInternet]);

  useInterval(() => {
    if (hasInternet == false && alarmsEnabled != false) {
      UiHelper.playNoInternetAudio();
    } else {
      if (shouldPlaySound(illPatients, silentItems, alarmsEnabled) == false) {
        return;
      }
      try {
        // audioRef.current.play();
        UiHelper.playSoundForIllPatients(notMutedIllPatients);
      } catch (exc) {}
    }
  }, 2000);

  useInterval(() => {
    let borderTime = +new Date() - 3 * 60 * 1000;
    let newSilentItems = silentItems.filter((x) => x.timestamp > borderTime);
    if (silentItems.length == newSilentItems.length) {
      return;
    }
    console.debug("newSilentItems", newSilentItems);
    setSilentItems(newSilentItems);
  }, 5000);

  // ePatients
  /*

     */

  let pagesNumber = Math.ceil(ePatients.length / pageSize);
  let pages = [];
  for (let i = 0; i < pagesNumber; i++) {
    pages.push(i);
  }
  // console.log("pagesNumber = ", pagesNumber);
  // console.log("pagepage pagesize", page, pageSize);

  // console.log("pagedPatients", pagedPatients.length, ePatients.length);

  let hasPages = patients.length > 1;

  ePatients = ePatients
    .map((p, i) => {
      // let battery_ = p?.realtime?.battery || realVal?.raw_activities?.battery;

      let latest_bp_data = p?.realtime?.latest_bp_data;
      let spo2 = p?.realtime?.spo2;
      let respiration_rate = p?.realtime?.respiration_rate;
      let heart_rate = p?.realtime?.heart_rate;
      let temper = p?.realtime?.temperature;
      // let bt = p?.realtime?.display_timestamp;
      let bt = p?.realtime?.raw_data_freshest_timestamp;
      let outdated = false;
      let outatedHours = 4;
      let secondsToNow = 0;

      if (bt != undefined) {
        let hoursToNow = -moment(bt).diff(moment(), "hours");
        secondsToNow = -moment(bt).diff(moment(), "seconds");
        // if (hoursToNow > outatedHours) {
        // outdated = true; // patient considered outdated if inactive for more than 4 hours,
        // }
      } else {
        secondsToNow = 3600 * 30 * 12 * 30;
        // outdated = true;
      }
      // const _params = {
      //     brpm: respiration_rate,
      //     spo2: spo2,
      //     spo2_2: "N/A",
      //     bp: latest_bp_data?.sbp_ai,
      //     pulse: heart_rate,
      //     temp: temper,
      //     consciousness: "N/A",
      //     airoroxygen: "N/A",
      // };

      // const alarmsPrioritiesMap = {
      //     pulse_rate: p?.realtime?.heart_rate_alarm_priority,
      //     respiration_rate: p?.realtime?.respiration_rate_alarm_priority,
      //     spo2: p?.realtime?.spo2_alarm_priority,
      //     temperature: p?.realtime?.temperature_alarm_priority,
      //     blood_pressure: 0,
      // };

      // const score = NewsScoreHelper.getNews2ScoreFromParams(_params);
      // const _score =
      //     NewsScoreHelper.getNewsScoreData(alarmsPrioritiesMap);

      return {
        ...p,
        // newsScore: score,
        // outdated: outdated,
        secondsToNow: secondsToNow,
      };
    })
    .sort((a, b) => {
      let onStudy = window.location.href.indexOf("study") > -1;

      if (onStudy && order !== "default") {
        return 0;
      }
      let scoreA = 0;
      let scoreB = 0;

      if (alarm.includes("news") && !onStudy) {
        scoreA = +a?.newsScoreData?.total ? a?.newsScoreData?.total : 0;
        scoreB = +b?.newsScoreData?.total ? b?.newsScoreData?.total : 0;
      } else if (alarm.includes("flag") && !onStudy) {
        scoreA = +a.maxPriority;
        scoreB = +b.maxPriority;
      } else {
      }
      if (a.outdated) {
        scoreA -= alarm.includes("news") ? 1000 : 0.5;
      } else {
        // scoreA -= a.secondsToNow / (3600 * 1000);
      }
      if (b.outdated) {
        scoreB -= alarm.includes("news") ? 1000 : 0.5;
      } else {
        // scoreB -= b.secondsToNow / (3600 * 1000);
      }
      if (hiddenPatientsIds.includes(a?.uuid)) {
        // moving hidden patients to the bottom
        scoreA -= 1000 * 1000;
      }
      if (hiddenPatientsIds.includes(b?.uuid)) {
        scoreB -= 1000 * 1000;
      }

      return +scoreB - +scoreA;
    });

  if (filter === "none") {
  } else if (filter === "hidden") {
    ePatients = ePatients.filter((x) => {
      return !hiddenPatientsIds.includes(x?.uuid);
    });
  } else if (filter === "notactivated") {
    ePatients = ePatients.filter((x) => {
      let { code } = x;
      return !code || code === "";
    });
  }
  let pagedPatients = ePatients;

  let _pagedPatients = pagedPatients;
  if (alarm.includes("none")) {
  } else if (alarm.includes("flag")) {
    _pagedPatients = ePatients.filter((x) => {
      if (selectedPriority === -1) {
        return true;
      } else if (selectedPriority === 0) {
        return (
          x.maxPriority == selectedPriority ||
          hiddenPatientsIds.includes(x?.uuid)
        );
      } else {
        return (
          x.maxPriority === selectedPriority &&
          !hiddenPatientsIds.includes(x?.uuid)
        );
      }
    });
  } else if (alarm.includes("news")) {
    _pagedPatients = ePatients.filter((x) => {
      if (selectedPriority === -1) {
        return true;
      } else if (selectedPriority === 0) {
        return (
          x?.newsScoreData?.level == selectedPriority && !x.outdated
          // hiddenPatientsIds.includes(x?.uuid)
        );
      } else if (selectedPriority === -2) {
        return x.outdated || hiddenPatientsIds.includes(x?.uuid);
      } else {
        return (
          x?.newsScoreData?.level === selectedPriority &&
          !hiddenPatientsIds.includes(x?.uuid) &&
          !x.outdated
        );
      }
    });
  } else {
    _pagedPatients = pagedPatients;
  }

  if (_pagedPatients.length < pageSize) {
    pagedPatients = _pagedPatients;
  } else {
    pagedPatients = _pagedPatients.slice(
      page * pageSize,
      (page + 1) * pageSize
    );
  }
  pagesNumber = Math.ceil(_pagedPatients.length / pageSize);
  pages = [];
  for (let i = 0; i < pagesNumber; i++) {
    pages.push(i);
  }
  // console.log("pagesNumber = ", pagesNumber);
  // console.log("pagepage pagesize", page, pageSize);

  // console.log("pagedPatients", pagedPatients.length, ePatients.length);

  hasPages = _pagedPatients.length > 1;

  // let pagedPatients = ePatients;

  const TopStatus = () => {
    return (
      <TopStatusContainer>
        <TopStatsStatusesPlaceholder>
          {[
            {
              ...PR_1,
              label: "High Priority",
            },
            {
              ...PR_2,
              label: "Medium Priority",
            },
            {
              ...PR_3,
              label: "Low Priority",
            },
            {
              ...NO_PRIORITY,
              label: "No Alarm",
            },
          ].map((p, i) => {
            let v = ePatients.filter((x) => {
              if (p.priority === 0) {
                return (
                  x.maxPriority == p.priority ||
                  hiddenPatientsIds.includes(x?.uuid)
                );
              }
              return (
                x.maxPriority == p.priority &&
                !hiddenPatientsIds.includes(x?.uuid)
              );
            }).length;
            // console.log(p);
            // console.log(
            //     ePatients.filter((x) => x.maxPriority == p.priority)
            //         .length
            // );

            return (
              <TopStatusItem
                key={i}
                className="priority-item"
                onClick={() => {
                  // console.log("pri", p.priority);
                  // setSelectedPriority(p.priority);
                  dispatch(UiActions.changePriority(p.priority));
                }}
                style={
                  selectedPriority === p.priority
                    ? {
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      }
                    : null
                }
              >
                <TopStatusSquare
                  className="priority-square"
                  color={p.color}
                ></TopStatusSquare>
                <TopStatusRight>
                  <TopStatusValue>{v}</TopStatusValue>
                  <div style={{ fontSize: 14 }}>{p.label}</div>
                </TopStatusRight>
              </TopStatusItem>
            );
          })}

          <TopStatusItem
            className="all-patient-priority-item"
            onClick={() => {
              dispatch(UiActions.changePriority(-1));
            }}
            style={
              selectedPriority === -1
                ? {
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }
                : null
            }
          >
            <TopStatusSquare className="all-patient-priority-square"></TopStatusSquare>
            <TopStatusRight>
              <TopStatusValue>{ePatients.length}</TopStatusValue>
              <div style={{ fontSize: 14 }}>{"All Patients"}</div>
            </TopStatusRight>
          </TopStatusItem>
        </TopStatsStatusesPlaceholder>
      </TopStatusContainer>
    );
  };

  const TopNEWSStatus = () => {
    return (
      <TopStatusContainer>
        <TopStatsStatusesPlaceholder>
          {[
            {
              ...NEWS_PR1,
              label: "High",
            },
            {
              ...NEWS_PR2,
              label: "Medium",
            },
            {
              ...NEWS_PR3,
              label: "Low - Medium",
            },
            {
              ...NEWS_NO_PR,
              label: "Low risk",
            },
            {
              // ...NEWS_NO_PR,
              color: "#eFeFeF",
              label: "Inactive",
              priority: -2,
            },
          ].map((p, i) => {
            let epf = ePatients.filter(
              (x) =>
                x?.newsScoreData?.level == p.priority &&
                !x.outdated &&
                !hiddenPatientsIds.includes(x?.uuid)
            );

            let v =
              p.label === "Inactive"
                ? ePatients.filter(
                    (x) => x.outdated || hiddenPatientsIds.includes(x?.uuid)
                  ).length
                : ePatients.filter(
                    (x) =>
                      x?.newsScoreData?.level == p.priority &&
                      !x.outdated &&
                      !hiddenPatientsIds.includes(x?.uuid)
                  ).length;
            return (
              <TopStatusItem
                key={i}
                className="priority-item"
                onClick={() => {
                  dispatch(UiActions.changePriority(p.priority));
                }}
                style={
                  selectedPriority === p.priority
                    ? {
                        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                      }
                    : null
                }
              >
                <TopStatusSquare
                  className="priority-square"
                  color={p.color}
                ></TopStatusSquare>
                <TopStatusRight>
                  <TopStatusValue>{v}</TopStatusValue>
                  <div style={{ fontSize: 14 }}>{p.label}</div>
                </TopStatusRight>
              </TopStatusItem>
            );
          })}
          <TopStatusItem
            className="all-patient-priority-item"
            onClick={() => {
              dispatch(UiActions.changePriority(-1));
            }}
            style={
              selectedPriority === -1
                ? {
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }
                : null
            }
          >
            <TopStatusSquare className="all-patient-priority-square"></TopStatusSquare>
            <TopStatusRight>
              <TopStatusValue>{ePatients.length}</TopStatusValue>
              <div style={{ fontSize: 14 }}>{"All Patients"}</div>
            </TopStatusRight>
          </TopStatusItem>
        </TopStatsStatusesPlaceholder>
        <NewsScoreToolTip>
          {/* About NEWS Score */}
          <NEWSTableTooltip className={"NEWSTableTooltip"}>
            <div
              style={{
                display: "flex",
                background: NEWS_NO_PR.color,
                paddingLeft: "1rem",
              }}
            >
              <i>Low risk: </i> Total 1-4
            </div>
            <div
              style={{
                display: "flex",
                background: NEWS_PR3.color,
                paddingLeft: "1rem",
              }}
            >
              <i>Low - Medium risk: </i> 3 in single parameter
            </div>
            <div
              style={{
                display: "flex",
                background: NEWS_PR2.color,
                paddingLeft: "1rem",
              }}
            >
              <i>Medium risk: </i>Total ≥ 5 or more urgent response threshold
            </div>
            <div
              style={{
                display: "flex",
                background: NEWS_PR1.color,
                paddingLeft: "1rem",
              }}
            >
              <i>High risk: </i>Total ≥ 7 or more emergency response threshold
            </div>
            {/* <div style={{background: "#333"}}>
              <a href="https://www.rcp.ac.uk/improving-care/resources/national-early-warning-score-news-2/">
                More about NEWS Score
              </a>
            </div> */}
          </NEWSTableTooltip>
        </NewsScoreToolTip>
      </TopStatusContainer>
    );
  };

  return (
    <Wrapper>
      {
        alarm === "none" ? null : window.location.href.indexOf("portal") ==
          -1 ? null : StatusType === "News2" ? (
          <TopNEWSStatus />
        ) : (
          <TopStatus />
        )
        // <TopStatus  />
        // <TopNEWSStatus />
      }

      <ListPlaceholder
        className={isAf ? "af-list-placeholder" : "list-placeholder"}
      >
        {pagedPatients.map((p, i) => {
          let { id, isDemo = false, uuid } = p;
          // console.log(p);
          // console.log(p?.deviceInfo);
          let bracelet = patientsBraceletsMap.get(p.uuid);
          let isHidden = hiddenPatientsIds.includes(p.uuid);
          let hasBracelet = bracelet != undefined;
          let portalAlertsMap = rangeItemsMap[p.uuid] || {};
          let { maxPriorityColor, maxPriority = 0 } = portalAlertsMap;
          let xRanges = getUserRanges(p.uuid);
          let {
            name,
            code,
            roomName,
            agoMinutes,
            battery,
            branch,
            doctorName,
            tags = [],
            diagnosis,
            metricsData = {},
          } = p;
          let compliance = groupCompliance[uuid];
          let cuffBpCount = groupCuffBp[uuid];

          let val = old_metricsMap[p?.uuid];
          let realVal = realMetricsMap[p?.uuid];

          // let battery_ = p?.realtime?.battery || realVal?.raw_activities?.battery;
          let battery_ = p?.realtime?.battery;
          // let latest_bp_data = p?.realtime?.latest_bp_data;

          // realtime bp
          let latest_bp_data = p?.realtime?.bp_data;
          let bp_value_string =
            latest_bp_data?.sbp == undefined
              ? "-"
              : `${latest_bp_data?.sbp}/${latest_bp_data?.dbp}`;
          let blood_pressure_alarm_color =
            p?.realtime?.blood_pressure_alarm_color;
          let blood_pressure_alarm_priority =
            p?.realtime?.blood_pressure_alarm_priority;

          // realtime SpO2
          // let spo2 = (p?.realtime?.spo2 == undefined || p?.realtime?.spo2 == 0 ? undefined : p?.realtime?.spo2) || realVal?.raw_activities?.spo2;
          let spo2 = p?.realtime?.spo2;
          let spo2_q = p?.realtime?.spo2_q;
          let spo2_priority = p?.realtime?.spo2_alarm_priority;

          // realtime respiration_rate
          // let respiration_rate = p?.realtime?.respiration_rate || realVal?.raw_activities?.respiration_rate;
          let respiration_rate = p?.realtime?.respiration_rate;
          let respiration_rate_q = p?.realtime?.respiration_rate_q;
          let respiration_rate_priority =
            p?.realtime?.respiration_rate_alarm_priority;
          // let heart_rate = p?.realtime?.heart_rate || realVal?.raw_activities?.bpm;
          let isCharging = p?.realtime?.isCharging == 1;

          // realtime heart_rate
          let heart_rate = p?.realtime?.heart_rate;
          let heart_rate_q = p?.realtime?.heart_rate_q;
          let heart_rate_priority = p?.realtime?.heart_rate_alarm_priority;

          // realtime temper
          let temper = p?.realtime?.temperature;
          let temper_priority = p?.realtime?.temperature_alarm_priority;
          let temperature_alarm_color = p?.realtime?.temperature_alarm_color;

          let af_burden = p?.af_burden || realVal?.af_burden;
          let dbTimestamp = realVal?.raw_activities?.bpm_timestamp;

          // console.log("dbTimestamp", dbTimestamp);
          // console.log(p);

          let bt = p?.realtime?.raw_data_freshest_timestamp;

          // let bt = p?.realtime?.display_timestamp; // temporary solution
          // console.log(name, p);
          // console.log("dbTimestamp", dbTimestamp);

          if (
            window.location.href.indexOf("study.corsano") > -1 &&
            dbTimestamp !== undefined
          ) {
            bt = dbTimestamp;
          }
          // console.log(name, bt, moment(bt).format("MMMM DD HH:mm"));

          // let bt = p?.realtime?.display_timestamp || realVal?.last_data_timestamp;

          let t_sk1 = realVal?.raw_temperatures?.temp_sk1;
          let t_sk2 = realVal?.raw_temperatures?.temp_sk2;

          // let temper_ = p?.realtime?.temperature || t_sk1 || t_sk2;
          // if (temper_ != undefined) {
          //     if (t_sk1 != undefined && t_sk2 != undefined) {
          //         temper_ = Math.max(t_sk1, t_sk2);
          //     }
          // }

          // let sleep_duration = val?.sleep_duration;
          let sleep_duration = p?.realtime?.sleep_duration;
          // let steps_number = val?.total_steps;
          let steps_number = p?.realtime?.total_steps;
          // let temper = temper_ || val?.temperature;
          // let temper = temper_;

          let heart_rate_alarm_color = p?.realtime?.heart_rate_alarm_color;
          let respiration_rate_alarm_color =
            p?.realtime?.respiration_rate_alarm_color;
          let spo2_alarm_color = p?.realtime?.spo2_alarm_color;

          let { temp_sk1, temp_sk2, temp_sk1_timestamp, temp_sk2_timestamp } =
            p?.realtime?.latest_filled_values?.raw_temperatures || {};
          let temperature_timestamp = undefined;
          // if (temp_sk1 != undefined) {
          //     temper = temp_sk1;
          //     temperature_timestamp = temp_sk1_timestamp;
          //     if (temp_sk2 != undefined) {
          //         if (+temp_sk2 > +temp_sk1) {
          //             temperature_timestamp = temp_sk2_timestamp;
          //             temper = temp_sk2;
          //         }
          //     }
          // }

          // let heart_rate = val?.bpm;

          if (temper != undefined && +temper > 43) {
            temper = undefined;
          }
          if (+spo2 < 50) {
            spo2 = undefined;
          }

          if (temper != undefined) {
            temper = temper.toFixed(1);
          }

          if (respiration_rate != undefined) {
            respiration_rate = respiration_rate.toFixed(1);
          }

          // console.log(alarmParams);

          for (const [key, value] of Object.entries(alarmParams)) {
            //  disbale alarm for vital parameters which alarmParams does not include in Between the flag mode
            if (value === false && alarm.includes("flag")) {
              switch (key) {
                case "heart_rate":
                  heart_rate_priority = 0;
                  heart_rate_alarm_color = NO_PRIORITY.color;
                  break;
                case "respiration_rate":
                  respiration_rate_priority = 0;
                  respiration_rate_alarm_color = NO_PRIORITY.color;
                  break;
                case "spo2":
                  spo2_alarm_color = NO_PRIORITY.color;
                  spo2_priority = 0;
                  break;
                case "systolic_blood_pressure":
                  blood_pressure_alarm_color = NO_PRIORITY.color;
                  blood_pressure_alarm_priority = 0;
                  break;
                case "temperature":
                  temper_priority = 0;
                  temperature_alarm_color = NO_PRIORITY.color;
                  break;
                default:
                // console.debug(`all vitals on`);
              }
            }
          }

          let sleep_duration_string =
            sleep_duration == undefined ? "-" : getSleepString(sleep_duration);

          let { breath_rate, temperature } = metricsData;
          let descName = `${code}${
            roomName == undefined ? "" : ` | ${roomName}`
          }`;
          let xName =
            p?.xName ||
            getCleanName(`${name}${code == undefined ? "" : ``}`, code);
          let _id = p.id || p.uuid;
          let device = devicesMap[_id];
          let metrics =
            metricsMap == undefined || metricsMap[p.uuid] == undefined
              ? {}
              : metricsMap[p.uuid];
          let { raw_temperatures = [], raw_activities = [] } = metrics;
          let temperObj = raw_temperatures[0];
          let actObj = raw_activities[0];
          // let heart_rate = actObj?.bpm;
          // let respiration_rate = actObj?.respiration_rate;

          let saturation = actObj?.saturation; // ?
          // let steps_number = actObj?.steps_number; // ?
          let blood_pressure = actObj?.blood_pressure; // ?
          let blood_pressure_up = actObj?.blood_pressure_up; // ?
          let blood_pressure_down = actObj?.blood_pressure_down; // ?

          // let temper = temperObj?.temp_sk1;
          // let actTimestamp = bt || actObj?.timestamp;
          let actTimestamp = bt;
          let tooLongAgo = +new Date() - +actTimestamp > 2 * 60 * 60 * 1000;
          let deltaAct =
            actTimestamp == undefined ? undefined : +new Date() - +actTimestamp;
          let agoColor = "#8798AD";
          if (deltaAct != undefined) {
            if (deltaAct > 10 * 60 * 1000) {
              agoColor = "rgb(234, 50, 35)";
            } else {
              if (deltaAct > 5 * 60 * 1000) {
                agoColor = "rgb(246, 222, 75)";
              }
            }
          }

          let unactivated = false;
          if (
            !p?.first_name &&
            !p?.last_name &&
            !p?.realtime?.display_timestamp
          ) {
            unactivated = true;
          }
          // let agoString = (window.location.href.indexOf('debug') == -1) ?  null : ( (isDemo == true) ? `${Math.floor(Math.random() * 5)} min ago` : (actTimestamp == undefined) ? '' : timeAgo.format(actTimestamp));
          // let agoString = (isDemo == true) ? `${Math.floor(Math.random() * 5)} min ago` : (actTimestamp == undefined) ? '' : timeAgo.format(actTimestamp);
          let agoString =
            !actTimestamp || !bt ? "" : timeAgo.format(actTimestamp);
          // console.log("agoString", actTimestamp, agoString, bt);
          // console.log("agoString", p);

          let outdated = p?.outdated;
          // console.log("AGOSTRING", agoString, bt, actTimestamp);

          if (bt != undefined) {
            let dt = +new Date() - +bt;
            if (dt < 2000) {
              agoString = `now`;
            } else {
              agoString =
                +dt < 60000
                  ? `${Math.floor(+dt / 1000.0)} seconds ago`
                  : moment(+bt).fromNow();
            }
          }

          let isMuted = silentItems.map((x) => x.uuid).indexOf(p.uuid) > -1;
          // console.log(silentItems);

          let osName = p?.appInfo?.os_name;
          let appVersion = p?.appInfo?.app_version;
          let newsScore = p?.newsScoreData?.level;
          let newsColor = alarm.includes("news")
            ? NewsScoreHelper.getColorByScore(newsScore)
            : undefined;
          if (p.outdated && alarm.includes("news")) {
            newsColor = undefined;
            maxPriority = 0;
          }
          // console.log(code, xName, `unactivated: ${unactivated}`);
          // console.log(p);

          if (layout === "big_tiles" && alarm.includes("news") && isPortal) {
            return (
              <PatientItem
                className={"patient-item"}
                $layout={layout}
                $maxpriority={maxPriority}
                $maxprioritycolor={maxPriorityColor}
                $news2color={newsColor}
                key={`patient_${_id}_${+i}`}
                onClick={() => {}}
                style={{
                  border: alarm == "none" ? "none" : "auto",
                  opacity: isHidden == true ? 0.4 : 1,
                }}
              >
                <PatientItemTop
                  $layout={layout ? layout : ""}
                  onClick={() => {}}
                  // style={{ border: "1px solid red" }}
                  className={
                    isAf
                      ? "patient-item-top af-patient-item-top"
                      : "patient-item-top"
                  }
                >
                  <NameSectionPlaceholderBigTiles className="name-section-placeholder">
                    <NamePlaceholder
                      style={{ fontSize: "18px" }}
                      className="patient-name"
                      onClick={() => {
                        if (p.state == "pre_created") {
                          return window.alert(
                            "This user is not activated. Please ask user to install the Corsano Trials app. And use voucher code."
                          );
                        }
                        onPatientClick(p);
                      }}
                    >
                      {xName}
                    </NamePlaceholder>
                    {window.location.href.indexOf("portal") > -1 &&
                    alarm.includes("news")
                      ? renderNewsOnTiles(
                          p?.newsScoreData,
                          "NEWS2",
                          "news2",
                          true,
                          lastMetricsLoading,
                          true,
                          outdated,
                          "big"
                        )
                      : null}
                  </NameSectionPlaceholderBigTiles>
                  <ParamsItemsPlaceholderBigTiles
                    className="params-items-placeholder"
                    onClick={() => {
                      if (p.state == "pre_created") {
                        return window.alert(
                          "This user is not activated. Please ask user to install the Corsano Trials app. And use voucher code."
                        );
                      }
                      onPatientClick(p);
                    }}
                  >
                    {renderParamEnhanced2(
                      heart_rate,
                      "BPM",
                      "heart_rate",
                      heart_rate_priority,
                      isMuted,
                      lastMetricsLoading,
                      heart_rate_q,
                      isPortal ? alarm : "none",
                      outdated,
                      heart_rate_alarm_color,
                      layout
                    )}
                    {window.location.href.indexOf("portal.") == -1
                      ? null
                      : renderParamEnhanced2(
                          bp_value_string,
                          // latest_bp_data?.sbp_ai,
                          "Blood Pressure",
                          "blood_pressure",
                          blood_pressure_alarm_priority,
                          false,
                          lastMetricsLoading,
                          "",
                          isPortal ? alarm : "none",
                          outdated,
                          blood_pressure_alarm_color,
                          layout
                        )}
                    {renderParamEnhanced2(
                      spo2,
                      "Saturation",
                      "spo2",
                      spo2_priority,
                      isMuted,
                      lastMetricsLoading,
                      spo2_q,
                      isPortal ? alarm : "none",
                      outdated,
                      spo2_alarm_color,
                      layout
                    )}
                    {renderParamEnhanced2(
                      respiration_rate,
                      "BRPM",
                      "respiration_rate",
                      respiration_rate_priority,
                      isMuted,
                      lastMetricsLoading,
                      respiration_rate_q,
                      isPortal ? alarm : "none",
                      outdated,
                      respiration_rate_alarm_color,
                      layout
                    )}
                    {renderParamEnhanced2(
                      temper,
                      // window.location.href.indexOf("portal") > -1
                      unit === "F"
                        ? `${layout}`.includes("small")
                          ? "Temp (F)"
                          : "Temperature (F)"
                        : `${layout}`.includes("small")
                        ? "Temp (°C)"
                        : "Temp (°C)",
                      "temperature",
                      temper_priority,
                      isMuted,
                      lastMetricsLoading,
                      "",
                      isPortal ? alarm : "none",
                      outdated,
                      temperature_alarm_color,
                      layout
                    )}
                    {!alarm.includes("news") ||
                    !isPortal ||
                    unactivated ? null : (
                      <BottomItem
                        alarm={alarm}
                        className="param-item"
                        onClick={(e) => {
                          e.stopPropagation();
                          onPatientSpotMeasurementsClick(p);
                        }}
                      >
                        <img
                          className="os-icon"
                          style={{
                            height: 24,
                            marginRight: 10,
                            opacity: 0.5,
                            cursor: "pointer",
                          }}
                          src={spot_iimg}
                        />
                        <SpotMeasurementsTool />
                      </BottomItem>
                    )}
                    {maxPriority == undefined || maxPriority == 0 ? null : (
                      <BottomItem
                        alarm={alarm}
                        className={`param-item ${
                          isAf ? "hidden-af" : ""
                        } bottom-item-1`}
                        style={{
                          // width: "30px",
                          cursor: "pointer",
                          width: "100%",
                          bottom: "1rem",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            silentItems.map((x) => x.uuid).indexOf(p.uuid) > -1
                          ) {
                            setSilentItems(
                              silentItems.filter((x) => x.uuid != p.uuid)
                            );
                          } else {
                            setSilentItems(
                              silentItems.concat([
                                {
                                  uuid: p.uuid,
                                  timestamp: +new Date(),
                                },
                              ])
                            );
                          }
                        }}
                      >
                        <Bell
                          width={24}
                          height={24}
                          type={
                            silentItems.map((x) => x.uuid).indexOf(p.uuid) == -1
                              ? `bell`
                              : "alarm_paused"
                          }
                        />
                        <BottomValue
                          style={{
                            marginLeft: 10,
                            fontSize: ".86em",
                          }}
                          className="bottom-value"
                        >
                          {silentItems.map((x) => x.uuid).indexOf(p.uuid) == -1
                            ? `Pause audio`
                            : "Audio paused"}
                        </BottomValue>
                      </BottomItem>
                    )}
                    {
                      <BottomItem
                        alarm={alarm}
                        className={`param-item bottom-item-1 ${
                          isAf ? "hidden-af" : ""
                        }`}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          bottom: "1rem",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();

                          onPatientAlarmClick(p);
                        }}
                      >
                        {/* <Bell width={24} height={24} /> */}
                        <img
                          style={{
                            height: 24,
                            marginRight: 10,
                            opacity: 0.5,
                          }}
                          src={bell_1}
                          className="setting-bell-icon"
                        />
                        <BottomValue className="bottom-value">{`Settings`}</BottomValue>
                      </BottomItem>
                    }
                    {!unactivated ? null : (
                      <BottomItem
                        className={`param-item`}
                        alarm={alarm}
                        onClick={() => {
                          onEnlargeQRCode(code);
                        }}
                        style={{
                          // marginRight: "40px",
                          display: "flex",
                          width: "max-content",
                          bottom: "1rem",
                          // display: "none"
                        }}
                      >
                        <img
                          className="os-icon"
                          style={{
                            height: 24,
                            marginRight: 10,
                            opacity: 0.5,
                          }}
                          src={qrcode_img}
                        />
                        <QRCodeSpan code={code} />
                      </BottomItem>
                    )}
                  </ParamsItemsPlaceholderBigTiles>
                </PatientItemTop>
                <PatientItemBottomBigTiles>
                  {agoString == "" || agoString == undefined ? null : (
                    <BottomItem
                      alarm={alarm}
                      className="bottom-item"
                      style={{ cursor: "pointer", width: "max-content" }}
                      // style={{ cursor: "pointer"}}
                      onClick={() => {
                        setActModalTimestamp(actTimestamp);
                      }}
                    >
                      <AgoSpan agoTimestamp={bt} layout={layout} />
                    </BottomItem>
                  )}

                  {!unactivated ? null : (
                    <BottomItem
                      alarm={alarm}
                      onClick={() => {}}
                      style={{ marginRight: "40px" }}
                    >
                      <img
                        className="os-icon"
                        style={{
                          height: 24,
                          marginRight: 10,
                          opacity: 0.5,
                        }}
                        src={num_img}
                      />
                      <BottomValue>
                        {code}{" "}
                        <i style={{ opacity: "65%" }}>
                          {unactivated ? "(not activated)" : ""}
                        </i>
                      </BottomValue>
                    </BottomItem>
                  )}

                  {outdated ? null : (
                    <BottomItem
                      alarm={alarm}
                      style={{ marginLeft: "1rem" }}
                      onClick={() => {}}
                    >
                      <BatterySpan
                        enableAutoLoading={
                          window.location.href.indexOf("af.") > -1
                        }
                        forceBattery={battery_}
                        uuid={p.uuid}
                        isCharging={isCharging}
                      />
                    </BottomItem>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      marginRight: 0,
                      right: 15,
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {layout === "small_tiles" || isHidden == true ? null : (
                      <BottomItem
                        alarm={alarm}
                        className="bottom-link-item"
                        style={{
                          cursor: "pointer",
                          display: isAf ? "none" : "flex",
                        }}
                        onClick={() => {
                          onHide(p);
                        }}
                      >
                        <UnlinkValue>{"Hide"}</UnlinkValue>
                      </BottomItem>
                    )}

                    {layout === "small_tiles" || isHidden == false ? null : (
                      <BottomItem
                        className="bottom-link-item"
                        style={{
                          cursor: "pointer",
                          marginRight: 20,
                          display: isAf ? "none" : "flex",
                        }}
                        onClick={() => {
                          onUnlinkClick(p);
                        }}
                      >
                        <img
                          style={{
                            height: 20,
                            marginRight: 10,
                            opacity: 0.4,
                          }}
                          src={unlink_img}
                          className="os-icon"
                        />
                        <UnlinkValue>{"Unlink"}</UnlinkValue>
                      </BottomItem>
                    )}

                    {layout === "small_tiles" || isHidden == false ? null : (
                      <BottomItem
                        className="bottom-link-item"
                        style={{
                          cursor: "pointer",
                          marginRight: 0,
                          display: isAf ? "none" : "flex",
                        }}
                        onClick={() => {
                          // if (!isHCPAdmin) {
                          //   toast.error(
                          //     "Only HCP Admin can hide or unhide patients!"
                          //   );
                          //   return;
                          // }
                          onUnhide(p);
                        }}
                      >
                        <UnlinkValue>{"Unhide"}</UnlinkValue>
                      </BottomItem>
                    )}
                  </div>
                </PatientItemBottomBigTiles>
              </PatientItem>
            );
          }

          if (layout === "small_tiles" && alarm.includes("news") && isPortal) {
            return (
              <PatientItem
                className={"patient-item"}
                layout={layout}
                $maxpriority={maxPriority}
                $maxprioritycolor={maxPriorityColor}
                $news2color={newsColor}
                key={`patient_${_id}_${+i}`}
                onClick={() => {}}
                style={{
                  border: alarm == "none" ? "none" : "auto",
                  opacity: isHidden == true ? 0.4 : 1,
                }}
              >
                <PatientItemTopSmallTiles
                  onClick={() => {}}
                  style={
                    {
                      // border: "1px solid blue",
                    }
                  }
                  className={
                    isAf
                      ? "patient-item-top af-patient-item-top"
                      : "patient-item-top"
                  }
                >
                  <NameSectionPlaceholderSmallTiles
                    className="name-section-placeholder"
                    style={
                      {
                        // border: "1px solid blue",
                        // boxSizing: "border-box",
                      }
                    }
                  >
                    <NamePlaceholder
                      style={{
                        fontSize: "16px",
                        // border: "1px solid blue",
                        // boxSizing: "border-box",
                      }}
                      className="patient-name"
                      onClick={() => {
                        if (p.state == "pre_created") {
                          return window.alert(
                            "This user is not activated. Please ask user to install the Corsano Trials app. And use voucher code."
                          );
                        }
                        onPatientClick(p);
                      }}
                    >
                      {xName}
                    </NamePlaceholder>
                    {window.location.href.indexOf("portal") > -1 &&
                    alarm.includes("news")
                      ? renderNewsOnTiles(
                          p?.newsScoreData,
                          "NEWS2",
                          "news2",
                          true,
                          lastMetricsLoading,
                          true,
                          outdated,
                          "small"
                        )
                      : null}
                  </NameSectionPlaceholderSmallTiles>
                  <ParamsItemsPlaceholderSmallTiles
                    className="params-items-placeholder"
                    style={
                      {
                        // border: "1px solid blue",
                        // boxSizing: "border-box",
                      }
                    }
                    onClick={() => {
                      if (p.state == "pre_created") {
                        return window.alert(
                          "This user is not activated. Please ask user to install the Corsano Trials app. And use voucher code."
                        );
                      }
                      onPatientClick(p);
                    }}
                  >
                    {renderParamEnhanced2(
                      heart_rate,
                      "BPM",
                      "heart_rate",
                      heart_rate_priority,
                      isMuted,
                      lastMetricsLoading,
                      heart_rate_q,
                      isPortal ? alarm : "none",
                      outdated,
                      heart_rate_alarm_color,
                      layout
                    )}
                    {renderParamEnhanced2(
                      spo2,
                      "Saturation",
                      "spo2",
                      spo2_priority,
                      isMuted,
                      lastMetricsLoading,
                      spo2_q,
                      isPortal ? alarm : "none",
                      outdated,
                      spo2_alarm_color,
                      layout
                    )}
                    {renderParamEnhanced2(
                      temper,
                      // window.location.href.indexOf("portal") > -1
                      unit === "F"
                        ? `${layout}`.includes("tiles")
                          ? "Temp (F)"
                          : "Temperature (F)"
                        : `${layout}`.includes("tiles")
                        ? "Temp (°C)"
                        : "Temperature (°C)",
                      "temperature",
                      temper_priority,
                      isMuted,
                      lastMetricsLoading,
                      "",
                      isPortal ? alarm : "none",
                      outdated,
                      temperature_alarm_color,
                      layout
                    )}
                    {!alarm.includes("news") ||
                    window.location.href.indexOf("portal") === -1 ||
                    unactivated ? null : (
                      <div
                        className="param-item"
                        style={{
                          // width: "100%",
                          // height: "100%",
                          justifySelf: "center",
                          // border: "1px solid blue",
                          // boxSizing: "border-box",
                        }}
                      >
                        <BottomItemSmallTiles
                          onClick={(e) => {
                            e.stopPropagation();
                            onPatientSpotMeasurementsClick(p);
                          }}
                        >
                          <img
                            className="os-icon"
                            style={{
                              height: 18,
                              marginRight: 6,
                              opacity: 0.5,
                              cursor: "pointer",
                            }}
                            src={spot_iimg}
                          />
                          <SpotMeasurementsTool fontsize="12px" />
                        </BottomItemSmallTiles>
                      </div>
                    )}
                    {maxPriority == undefined || maxPriority == 0 ? null : (
                      <div
                        className="param-item"
                        style={{
                          // justifySelf: "center",
                          width: "120%",
                          // justifySelf: "end",
                          // border: "1px solid blue",
                          // boxSizing: "border-box",
                          height: "1rem",
                          marginTop: "0px",
                        }}
                      >
                        <div className={isAf ? "hidden-af" : ""}>
                          {
                            <BottomItemSmallTiles
                              className="bottom-item-1"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  silentItems
                                    .map((x) => x.uuid)
                                    .indexOf(p.uuid) > -1
                                ) {
                                  setSilentItems(
                                    silentItems.filter((x) => x.uuid != p.uuid)
                                  );
                                } else {
                                  setSilentItems(
                                    silentItems.concat([
                                      {
                                        uuid: p.uuid,
                                        timestamp: +new Date(),
                                      },
                                    ])
                                  );
                                }
                              }}
                            >
                              <Bell
                                width={20}
                                height={20}
                                type={
                                  silentItems
                                    .map((x) => x.uuid)
                                    .indexOf(p.uuid) == -1
                                    ? `bell`
                                    : "alarm_paused"
                                }
                              />
                              <BottomValue
                                style={{
                                  marginLeft: 6,
                                  fontSize: "12px",
                                }}
                                className="bottom-value"
                              >
                                {silentItems
                                  .map((x) => x.uuid)
                                  .indexOf(p.uuid) == -1
                                  ? `Pause audio`
                                  : "Audio paused"}
                              </BottomValue>
                            </BottomItemSmallTiles>
                          }
                        </div>
                      </div>
                    )}
                    {
                      <div
                        className="param-item"
                        style={{
                          justifySelf: "center",
                          // width: "100%",
                          // left: "-6px",
                          // border: "1px solid blue",
                          // boxSizing: "border-box",
                          marginTop: "0px",
                          height: "1rem",
                          marginLeft: "12px",
                        }}
                      >
                        <div className={isAf ? "hidden-af" : ""}>
                          <BottomItemSmallTiles
                            style={{
                              cursor: "pointer",
                              // marginTop: "-0.8em",
                              // marginLeft: 24,
                            }}
                            className="bottom-item-1"
                            onClick={(e) => {
                              e.stopPropagation();

                              onPatientAlarmClick(p);
                            }}
                          >
                            {/* <Bell width={24} height={24} /> */}
                            <img
                              style={{
                                // height: 24,
                                // width: "20px",
                                marginRight: 6,
                                opacity: 0.5,
                              }}
                              src={bell_1}
                              className="setting-bell-icon"
                            />
                            <BottomValue
                              className="bottom-value"
                              style={{ fontSize: "12px" }}
                            >{`Settings`}</BottomValue>
                          </BottomItemSmallTiles>
                        </div>
                      </div>
                    }
                  </ParamsItemsPlaceholderSmallTiles>
                </PatientItemTopSmallTiles>
                <PatientItemBottomSmallTiles>
                  {agoString == "" || agoString == undefined ? null : (
                    <BottomItemSmallTiles
                      className="bottom-item"
                      style={{ cursor: "pointer", width: "max-content" }}
                      // style={{ cursor: "pointer"}}
                      onClick={() => {
                        setActModalTimestamp(actTimestamp);
                      }}
                    >
                      <AgoSpan agoTimestamp={bt} layout={layout} />
                    </BottomItemSmallTiles>
                  )}
                  {!unactivated ? null : (
                    <BottomItemSmallTiles
                      onClick={() => {}}
                      style={{ marginRight: "40px" }}
                    >
                      <img
                        className="os-icon"
                        style={{
                          height: 24,
                          marginRight: 10,
                          opacity: 0.5,
                        }}
                        src={num_img}
                      />
                      <BottomValue>
                        {code}
                        <i style={{ opacity: "65%" }}>
                          {unactivated ? "(not activated)" : ""}
                        </i>
                      </BottomValue>
                    </BottomItemSmallTiles>
                  )}
                  {!unactivated ? null : (
                    <BottomItemSmallTiles
                      onClick={() => {
                        onEnlargeQRCode(code);
                      }}
                      style={{
                        marginRight: "40px",
                        display: layout.includes("tile") ? "none" : "flex",
                        // display: "none",
                      }}
                    >
                      <img
                        className="os-icon"
                        style={{
                          height: 24,
                          marginRight: 10,
                          opacity: 0.5,
                        }}
                        src={qrcode_img}
                      />
                      <QRCodeSpan code={code} />
                    </BottomItemSmallTiles>
                  )}
                  {outdated ? null : (
                    <BottomItemSmallTiles
                      style={{ marginLeft: "1rem", width: "50px" }}
                      onClick={() => {}}
                    >
                      <BatterySpan
                        enableAutoLoading={
                          window.location.href.indexOf("af.") > -1
                        }
                        forceBattery={battery_}
                        uuid={p.uuid}
                        isCharging={isCharging}
                      />
                    </BottomItemSmallTiles>
                  )}
                </PatientItemBottomSmallTiles>
              </PatientItem>
            );
          }

          return (
            <PatientItem
              className={"patient-item"}
              layout={layout}
              $maxpriority={maxPriority}
              $maxprioritycolor={maxPriorityColor}
              $news2color={newsColor}
              key={`patient_${_id}_${+i}`}
              onClick={() => {}}
              style={{
                border: alarm == "none" ? normalBorder : "auto",
                opacity: isHidden == true ? 0.4 : 1,
              }}
            >
              <PatientItemTop
                $layout={layout ? layout : ""}
                onClick={() => {}}
                className={
                  isAf
                    ? "patient-item-top af-patient-item-top"
                    : "patient-item-top"
                }
              >
                <NameSectionPlaceholder
                  layout={layout}
                  className="name-section-placeholder"
                  style={{
                    marginBottom: layout.includes("small") ? "5px" : "0px",
                  }}
                >
                  <NamePlaceholder
                    style={{
                      fontSize: layout.includes("small") ? "16px" : "18px",
                    }}
                    className="patient-name"
                    onClick={() => {
                      if (p.state == "pre_created") {
                        return window.alert(
                          "This user is not activated. Please ask user to install the Corsano Trials app. And use voucher code."
                        );
                      }
                      onPatientClick(p);
                    }}
                  >
                    {xName}
                  </NamePlaceholder>

                  {getLabelsInfoData().bracelets_enabled == false ? null : (
                    <SelectBraceletTool
                      {...p}
                      patientUUID={p.uuid}
                      onSelected={() => {
                        onBraceletChanged(p.uuid);
                      }}
                    />
                  )}

                  {/*<DescriptionPlaceholder>*/}
                  {/*    {(descName == undefined || descName == null) ? '' : descName}*/}
                  {/*</DescriptionPlaceholder>*/}
                </NameSectionPlaceholder>

                <ParamsItemsPlaceholder
                  layout={layout}
                  onstudy={window.location.href.indexOf("study.") > -1}
                  className="params-items-placeholder"
                  onClick={() => {
                    if (p.state == "pre_created") {
                      return window.alert(
                        "This user is not activated. Please ask user to install the Corsano Trials app. And use voucher code."
                      );
                    }
                    onPatientClick(p);
                  }}
                >
                  {window.location.href.indexOf("portal") > -1 &&
                  alarm.includes("news")
                    ? renderNews2(
                        p?.newsScoreData,
                        "NEWS2",
                        "news2",
                        true,
                        lastMetricsLoading,
                        true,
                        outdated
                      )
                    : null}
                  {/* ["af", "BPM", "bp", "spo2", "brpm", "steps", "sleep", "temp"] */}

                  {renderParamEnhanced2(
                    `${af_burden || "0"} %`,
                    "AF Burden",
                    "heart_rate",
                    heart_rate_priority,
                    isMuted,
                    lastMetricsLoading,
                    heart_rate_q,
                    isPortal ? alarm : "none",
                    outdated,
                    heart_rate_alarm_color,
                    layout
                  )}
                  {renderParamEnhanced2(
                    heart_rate,
                    "BPM",
                    "heart_rate",
                    heart_rate_priority,
                    isMuted,
                    lastMetricsLoading,
                    heart_rate_q,
                    isPortal ? alarm : "none",
                    outdated,
                    heart_rate_alarm_color,
                    layout
                  )}
                  {window.location.href.indexOf("portal.") == -1 ||
                  (isPortal && layout === "normal")
                    ? null
                    : renderParamEnhanced2(
                        bp_value_string,
                        // latest_bp_data?.sbp_ai,
                        "Blood Pressure",
                        "blood_pressure",
                        blood_pressure_alarm_priority,
                        false,
                        lastMetricsLoading,
                        "",
                        isPortal ? alarm : "none",
                        outdated,
                        blood_pressure_alarm_color,
                        layout
                      )}
                  {/*{renderParamEnhanced(spo2, 'Saturation', 'spo2', spo2_ok, portalAlertsMap, isMuted, lastMetricsLoading, p?.realtime?.spo2_timestamp)}*/}
                  {renderParamEnhanced2(
                    spo2,
                    "Saturation",
                    "spo2",
                    spo2_priority,
                    isMuted,
                    lastMetricsLoading,
                    spo2_q,
                    isPortal ? alarm : "none",
                    outdated,
                    spo2_alarm_color,
                    layout
                  )}
                  {renderParamEnhanced2(
                    respiration_rate,
                    "BRPM",
                    "respiration_rate",
                    respiration_rate_priority,
                    isMuted,
                    lastMetricsLoading,
                    respiration_rate_q,
                    isPortal ? alarm : "none",
                    outdated,
                    respiration_rate_alarm_color,
                    layout
                  )}

                  {tag == "TMKYF" ||
                  layout === "small_tiles" ||
                  window.location.href.indexOf("portal.") > -1
                    ? null
                    : renderParam(
                        outdated ? "-" : steps_number,
                        "Steps",
                        footprint_ok,
                        footprint_danger,
                        false,
                        lastMetricsLoading,
                        isPortal ? alarm : "none"
                      )}

                  {hasBracelet == true ||
                  window.location.href.indexOf("portal") > -1 ||
                  layout === "small_tiles"
                    ? null
                    : renderParam(
                        outdated ? "-" : sleep_duration_string,
                        "Sleep",
                        sleep_ok,
                        sleep_ok,
                        false,
                        lastMetricsLoading,
                        isPortal ? alarm : "none"
                      )}

                  {window.location.href.indexOf("portal.") == -1 ||
                  (isPortal && layout !== "normal")
                    ? null
                    : renderParamEnhanced2(
                        bp_value_string,
                        // latest_bp_data?.sbp_ai,
                        "Blood Pressure",
                        "blood_pressure",
                        blood_pressure_alarm_priority,
                        false,
                        lastMetricsLoading,
                        "",
                        isPortal ? alarm : "none",
                        outdated,
                        blood_pressure_alarm_color,
                        layout
                      )}
                  {renderParamEnhanced2(
                    temper,
                    unit === "F"
                      ? `${layout}`.includes("tiles")
                        ? "Temp (F)"
                        : "Temperature (F)"
                      : `${layout}`.includes("tiles")
                      ? "Temp (°C)"
                      : "Temperature (°C)",
                    "temperature",
                    temper_priority,
                    isMuted,
                    lastMetricsLoading,
                    "",
                    isPortal ? alarm : "none",
                    outdated,
                    temperature_alarm_color,
                    layout
                  )}

                  {layout === "big_tiles" ? (
                    <div
                      className={`param-item param-item-${alarm}`}
                      style={{
                        height: "49px",
                        display:
                          window.location.href.indexOf("study.") > 0
                            ? "none"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          // display: "flex",
                          width: "200%",
                        }}
                        className={isAf ? "hidden-af" : ""}
                      >
                        {maxPriority == undefined || maxPriority == 0 ? (
                          <></>
                        ) : (
                          <BottomItem
                            className="bottom-item-1"
                            style={{
                              // width: "30px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                silentItems.map((x) => x.uuid).indexOf(p.uuid) >
                                -1
                              ) {
                                setSilentItems(
                                  silentItems.filter((x) => x.uuid != p.uuid)
                                );
                              } else {
                                setSilentItems(
                                  silentItems.concat([
                                    {
                                      uuid: p.uuid,
                                      timestamp: +new Date(),
                                    },
                                  ])
                                );
                              }
                            }}
                          >
                            <Bell
                              width={24}
                              height={24}
                              type={
                                silentItems
                                  .map((x) => x.uuid)
                                  .indexOf(p.uuid) == -1
                                  ? `bell`
                                  : "alarm_paused"
                              }
                            />
                            <BottomValue
                              style={{
                                marginLeft: 10,
                                fontSize: ".86em",
                              }}
                              className="bottom-value"
                            >
                              {silentItems.map((x) => x.uuid).indexOf(p.uuid) ==
                              -1
                                ? `Pause audio`
                                : "Audio paused"}
                            </BottomValue>
                          </BottomItem>
                        )}
                        <BottomItem
                          style={{
                            cursor: "pointer",
                            // marginTop: "-0.8em",
                            marginLeft: 24,
                          }}
                          className="bottom-item-1"
                          onClick={(e) => {
                            e.stopPropagation();

                            onPatientAlarmClick(p);
                          }}
                        >
                          <BottomValue
                            style={{
                              marginLeft: 10,
                            }}
                            className="bottom-value"
                          >{`Settings`}</BottomValue>
                        </BottomItem>
                      </div>
                    </div>
                  ) : null}
                </ParamsItemsPlaceholder>
              </PatientItemTop>

              <PatientItemBottom className="patient-item-bottom">
                {agoString == "" || agoString == undefined ? null : (
                  <BottomItem
                    className="bottom-item"
                    style={{
                      cursor: "pointer",
                      width: "max-content",
                    }}
                    // style={{ cursor: "pointer"}}
                    onClick={() => {
                      setActModalTimestamp(actTimestamp);
                    }}
                  >
                    <AgoSpan agoTimestamp={bt} layout={layout} />
                    {/*<img style={{height: 18, marginRight: 10}} src={zmImage}/>*/}
                    {/*<BottomValue tooLongAgo={tooLongAgo}*/}
                    {/*             style={{color: agoColor}}>*/}
                    {/*    {`${agoString}`}*/}
                    {/*</BottomValue>*/}
                  </BottomItem>
                )}

                {window.location.href.indexOf("portal") == -1 ? null : (
                  <>
                    {maxPriority == undefined || maxPriority == 0 ? null : (
                      <BottomItem
                        className="bottom-item bottom-bell-item"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (
                            silentItems.map((x) => x.uuid).indexOf(p.uuid) > -1
                          ) {
                            setSilentItems(
                              silentItems.filter((x) => x.uuid != p.uuid)
                            );
                          } else {
                            setSilentItems(
                              silentItems.concat([
                                {
                                  uuid: p.uuid,
                                  timestamp: +new Date(),
                                },
                              ])
                            );
                          }
                        }}
                      >
                        {/*<img style={{height: 24, marginRight: 10}} src={dismiss_icon}/>*/}
                        <Bell
                          width={24}
                          height={24}
                          type={
                            silentItems.map((x) => x.uuid).indexOf(p.uuid) == -1
                              ? `bell`
                              : "alarm_paused"
                          }
                        />
                        <BottomValue
                          style={{
                            marginLeft: 10,
                          }}
                          className="bottom-value"
                        >
                          {silentItems.map((x) => x.uuid).indexOf(p.uuid) == -1
                            ? `Pause audio`
                            : "Audio paused"}
                        </BottomValue>
                      </BottomItem>
                    )}

                    {layout === "small_tiles" ? null : (
                      <BottomItem
                        className="bottom-item bottom-item-setting"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          onPatientAlarmClick(p);
                        }}
                      >
                        <img
                          style={{
                            height: 24,
                            marginRight: 10,
                          }}
                          src={alarm_img}
                          className="setting-bell-icon"
                        />
                        <BottomValue className="bottom-value">{`Settings`}</BottomValue>
                      </BottomItem>
                    )}
                  </>
                )}

                {!alarm.includes("news") ||
                window.location.href.indexOf("portal") === -1 ||
                // !(code == undefined || code == "") ? null : (
                unactivated ? null : (
                  <BottomItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onPatientSpotMeasurementsClick(p);
                    }}
                    style={{
                      marginRight: "40px",
                      display: layout.includes("tile") ? "none" : "flex",
                      // display: "none",
                    }}
                  >
                    <img
                      className="os-icon"
                      style={{
                        height: 24,
                        marginRight: 10,
                        opacity: 0.5,
                        cursor: "pointer",
                      }}
                      src={spot_iimg}
                    />
                    <SpotMeasurementsTool />
                  </BottomItem>
                )}

                {!unactivated ? null : (
                  <BottomItem
                    onClick={() => {}}
                    style={{ marginRight: "40px" }}
                  >
                    <img
                      className="os-icon"
                      style={{
                        height: 24,
                        marginRight: 10,
                        opacity: 0.5,
                      }}
                      src={num_img}
                    />
                    <BottomValue style={{ width: "max-content" }}>
                      {layout.includes("tiles") ? "" : `${code}`}{" "}
                      <i
                        style={{
                          opacity: "65%",
                          fontSize: layout.includes("small") ? "12px" : "14px",
                        }}
                      >
                        {unactivated ? "(not activated)" : ""}
                      </i>
                    </BottomValue>
                  </BottomItem>
                )}

                {!unactivated || isAf ? null : (
                  <BottomItem
                    onClick={() => {
                      onEnlargeQRCode(code);
                    }}
                    style={{
                      marginRight: "40px",
                      display: layout.includes("tile") ? "none" : "flex",
                      // display: "none",
                    }}
                  >
                    <img
                      className="os-icon"
                      style={{
                        height: 24,
                        marginRight: 10,
                        opacity: 0.5,
                      }}
                      src={qrcode_img}
                    />
                    <QRCodeSpan code={code} />
                  </BottomItem>
                )}

                <BottomItem onClick={() => {}}>
                  <BatterySpan
                    enableAutoLoading={window.location.href.indexOf("af.") > -1}
                    forceBattery={battery_}
                    uuid={p.uuid}
                    isCharging={isCharging}
                  />
                </BottomItem>
                {!layout.includes("tile") && compliance ? (
                  <BottomItem style={{ display: isAf ? "none" : "none" }}>
                    <ComplianceSpan compliance={compliance} />
                  </BottomItem>
                ) : null}

                {!layout.includes("tile") && !unactivated ? (
                  <BottomItem style={{ display: isAf ? "none" : "block" }}>
                    <CuffBpSpan
                      isDebugMode={isDebugMode()}
                      cuffBpCount={cuffBpCount}
                    />
                  </BottomItem>
                ) : null}
                <div
                  style={{
                    position: "absolute",
                    marginRight: 0,
                    right: 15,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {p?.deviceInfo?.device_type == undefined ||
                  p?.deviceInfo?.firmware_version == undefined ? null : (
                    <div
                      className="bottom-link-item"
                      style={{
                        fontSize: 12,
                        opacity: 0.6,
                        marginRight: 15,
                        color: "#8798AD",
                      }}
                    >
                      {`${p?.deviceInfo?.device_type} `}
                      {"(FW "}
                      {osName && (
                        <span>
                          <img
                            src={osName == "ios" ? ios_icon : android_icon}
                            style={{
                              height: 14,
                            }}
                            className="os-icon"
                          />
                        </span>
                      )}
                      {p?.deviceInfo?.firmware_version}
                      {")"}
                    </div>
                  )}

                  {layout === "small_tiles" || isHidden == true ? null : (
                    <BottomItem
                      className="bottom-link-item"
                      style={{
                        cursor: "pointer",
                        marginRight: 0,
                        display: isAf ? "none" : "flex",
                      }}
                      onClick={() => {
                        // if (!isHCPAdmin) {
                        //   toast.error(
                        //     "Only HCP Admin can hide or unhide patients!"
                        //   );
                        //   return;
                        // }
                        onHide(p);
                      }}
                    >
                      <UnlinkValue>{"Hide"}</UnlinkValue>
                    </BottomItem>
                  )}

                  {layout === "small_tiles" || isHidden == false ? null : (
                    <BottomItem
                      className="bottom-link-item"
                      style={{
                        cursor: "pointer",
                        marginRight: 20,
                        display: isAf ? "none" : "flex",
                      }}
                      onClick={() => {
                        // if (!isHCPAdmin) {
                        //   toast.error("Only HCP Admin can unlink patients!");
                        //   return;
                        // }
                        onUnlinkClick(p);
                      }}
                    >
                      <img
                        style={{
                          height: 20,
                          marginRight: 10,
                          opacity: 0.4,
                        }}
                        src={unlink_img}
                        className="os-icon"
                      />
                      <UnlinkValue>{"Unlink"}</UnlinkValue>
                    </BottomItem>
                  )}

                  {layout === "small_tiles" || isHidden == false ? null : (
                    <BottomItem
                      className="bottom-link-item"
                      style={{
                        cursor: "pointer",
                        marginRight: 0,
                        display: isAf ? "none" : "flex",
                      }}
                      onClick={() => {
                        // if (!isHCPAdmin) {
                        //   toast.error(
                        //     "Only HCP Admin can hide or unhide patients!"
                        //   );
                        //   return;
                        // }
                        onUnhide(p);
                      }}
                    >
                      <UnlinkValue>{"Unhide"}</UnlinkValue>
                    </BottomItem>
                  )}
                </div>

                {device == undefined ? null : (
                  <BottomItem
                    className="bottom-device-item"
                    style={{
                      display: isAf ? "none" : "flex",
                    }}
                  >
                    <BottomLabel>Bracelet:</BottomLabel>
                    <BottomValue>{device.name}</BottomValue>
                  </BottomItem>
                )}
              </PatientItemBottom>
            </PatientItem>
          );
        })}
      </ListPlaceholder>

      {hasPages == false ? null : (
        <BottomPlaceholder className="mt-5 mb-5 flex flex-row items-center justify-between">
          <div className="normal-text">
            {`${pageSize * page + 1} - ${Math.min(
              (page + 1) * pageSize,
              _pagedPatients.length
            )}`}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className="flex-1 flex flex-wrap "
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginRight: "50px",
              }}
            >
              {pages.length < 2 ? null : (
                <>
                  {pages.map((a, i) => {
                    return (
                      <PageItem
                        selected={a == page}
                        style={{
                          textDecoration: a == page ? "underline" : "none",
                        }}
                        key={`aa${a}_${i}`}
                        onClick={() => {
                          ls.set("current-page", a);
                          setPage(a);
                        }}
                      >
                        {+a + 1}
                      </PageItem>
                    );
                  })}
                </>
              )}
            </div>
            {/* <div style={{ width: 90 }}>
              <Select
                isSearchable={false}
                menuIsOpen={pageSelectorOpen}
                onMenuOpen={() => setPageSelectorOpen(true)}
                onMenuClose={() => setPageSelectorOpen(false)}
                isClearable={false}
                options={isAf ? AF_PAGE_SIZE_OPTIONS : PAGE_SIZE_OPTIONS}
                value={
                  isAf
                    ? AF_PAGE_SIZE_OPTIONS.find((x) => x.value === pageSize)
                    : PAGE_SIZE_OPTIONS.find((x) => x.value === pageSize)
                }
                onChange={(x) => {
                  setPageSize(x?.value);
                }}
                styles={SelectorStyles}
              />
            </div> */}
            {/* <PageSelector
              SetPageSize={setPageSize}
              isAF={isAf}
              pageSize={pageSize}
            /> */}
            {pageSelector}
          </div>
        </BottomPlaceholder>
      )}

      {actModalTimestamp == undefined ? null : (
        <NiceModal
          onClose={() => {
            setActModalTimestamp(undefined);
          }}
        >
          <ActModalInner>
            <div style={{ textAlign: "center", padding: "10px;" }}>
              <img
                style={{ height: 80, display: "inline-block" }}
                src={
                  +new Date() - +actModalTimestamp < 5 * 60 * 1000
                    ? ok_cloud_image
                    : bad_cloud_image
                }
              />
            </div>

            {+new Date() - +actModalTimestamp < 5 * 60 * 1000 ? (
              <div className="normal-text">
                <p>
                  {`Portal is connected to Cloud and last data was received at ${moment(
                    actModalTimestamp
                  ).format("DD.MM.YYYY HH:mm")}`}
                </p>
              </div>
            ) : (
              <div className="normal-text">
                <p>
                  {`Connection was lost at ${moment(actModalTimestamp).format(
                    "DD.MM.YYYY HH:mm"
                  )}, please follow these steps:`}
                </p>
                <ul>
                  <li>
                    Make sure that your computer is connected to the internet
                  </li>
                  <li>
                    If empty battery, please ask patient to charge CardioWatch
                    Bracelet
                  </li>
                  <li>
                    Check with patient if connection between the bracelet and
                    app is lost. If not, follow Bluetooth troubleshooting
                  </li>
                  <li>
                    Check with patient if connection between the app and the
                    cloud is lost. If not, follow Cloud troubleshooting
                  </li>
                </ul>
              </div>
            )}
          </ActModalInner>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const PageSelector = (props) => {
  return (
    <div style={{ width: 90 }}>
      <Select
        isSearchable={false}
        // menuIsOpen={pageSelectorOpen}
        // onMenuOpen={() => setPageSelectorOpen(true)}
        // onMenuClose={() => setPageSelectorOpen(false)}
        isClearable={false}
        options={props.isAF ? AF_PAGE_SIZE_OPTIONS : PAGE_SIZE_OPTIONS}
        value={
          props.isAf
            ? AF_PAGE_SIZE_OPTIONS.find((x) => x.value === props.pageSize)
            : PAGE_SIZE_OPTIONS.find((x) => x.value === props.pageSize)
        }
        onChange={(x) => {
          props.SetPageSize(x?.value);
          // setPageSize(x?.value);
        }}
        styles={SelectorStyles}
      />
    </div>
  );
};
const SelectorStyles = {
  menu: () => ({
    // position: "absolute",
    right: 0,
    bottom: "30px",
  }),
};

const PageItem = styled.div`
  padding: 10px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: ${(props) => (props.selected == true ? "bold" : "normal")};
`;

const ActModalInner = styled.div`
  padding: 10px;
  width: 520px;
`;

const TopStatusContainer = styled.div`
  // display:flex;
  margin-bottom: 20px;
`;

const NewsScoreToolTip = styled.div`
  text-decoration: underline;
  display: flex;
  cursor: pointer;
  margin-top: 6px;

  &:hover {
    .NEWSTableTooltip {
      color: red;
      display: block;
    }
  }
`;

const NEWSTableTooltip = styled.div`
  display: none;
  background: #333;
  border: 1px solid black;
  position: absolute;
  top: 160px;
  left: 200px;
  width: 420px;
  // text-align: center;
  z-index: 100;
  font-size: 14px;
`;

const TopStatsStatusesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  // margin-bottom: 20px;
`;

const TopStatusItem = styled.div`
  background: white;
  border: whitesmoke;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  cursor: pointer;
`;

const TopStatusSquare = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  background: ${(props) => props.color};
  margin-right: 10px;
`;

const TopStatusRight = styled.div`
  line-height: 18px;
`;

const TopStatusValue = styled.div`
  font-weight: bold;
`;

const BottomLabel = styled.div`
  color: #000f4b;
  font-size: 14px;
  line-height: 32px;
  margin-right: 8px;
`;

const BottomValue = styled.span`
  font-size: 14px;
  line-height: 32px;
  font-weight: bold;
  color: #8798ad;
`;

const UnlinkValue = styled(BottomValue)`
  opacity: 0.4;
  font-weight: 700;

  :hover {
    opacity: 1;
  }
`;

const NameSectionPlaceholder = styled.div`
  ${(props) => {
    switch (props.layout) {
      case "normal":
        return css`
          // width: max-content;
          align-items: center;
          justify-content: center;
          // border: 1px solid grey;
          width: 220px;
          // height: 3rem;
          // margin-bottom: 0.5rem;
          margin-right: 1rem;
          padding-right: ${(props) =>
            props.layout.includes("small") ? "4px" : "5px"};
          padding-left: ${(props) =>
            props.layout.includes("small") ? "4px" : "10px"};
        `;
      default:
        return css`
          // width: max-content;
          align-items: center;
          justify-content: center;
          // border: 1px solid grey;
          width: 220px;
          // height: 3rem;
          // margin-bottom: 0.5rem;
          margin-right: 1rem;
          padding-right: ${(props) =>
            props.layout.includes("small") ? "4px" : "5px"};
          padding-left: ${(props) =>
            props.layout.includes("small") ? "4px" : "10px"};
        `;
    }
  }}
`;

const NamePlaceholder = styled.div`
  font-weight: bold;
  // height: 3rem;
  // font-size: 18px;
  // line-height: 28px;
  margin-bottom: 4px;
  cursor: pointer;
`;

const DescriptionPlaceholder = styled.div`
  color: #8798ad;
`;

const PatientItemTop = styled.div`
  display: flex;
  max-width: ${(props) => (props.$layout.includes("small") ? "180px" : null)};
  // flex-direction: row;
  // align-items: flex-start;
  padding-top: 12px;
  // padding-right: 15px;
  // padding-bottom: 10px;
  box-sizing: border-box;
`;

const ParamsItemsPlaceholder = styled.div`
  ${(props) => {
    switch (props.layout) {
      case "normal":
        return css`
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          cursor: pointer;
          flex: 1;
          // grid-template-columns: repeat(2, 1fr);
          & div.param-item-news:nth-child(1) {
            // left: 4px;
            // display: none
          }
          & div.param-item-news:nth-child(2) {
            right: -4px;
            // display: none
          }
          & div.param-item-news:nth-child(3) {
            right: 12px;
          }
          & div.param-item-news:nth-child(4) {
            right: 10px;
          }
          & div.param-item-news:nth-child(5) {
            right: 28px;
          }
        `;
      case "big_tiles":
        return css`
          display: grid !important;
          align-items: flex-start;
          padding: 10px;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          /* justify-content: space-between; */
          /* justify-content: start; */
          /* justify-items: start; */
          /* gap: 0px 3px; */
          row-gap: 1.4rem;
          & div.param-item:nth-child(2) {
            right: 30px;
          }
          & div.param-item-none:nth-child(2) {
            // right: 24px;
            right: ${(props) => (props.onstudy ? "16px" : "36px")};
          }
          & div.param-item-none:nth-child(4) {
            right: ${(props) => (props.onstudy ? "16px" : "24px")};
          }
          & div.param-item-none:nth-child(6) {
            right: ${(props) => (props.onstudy ? "16px" : "24px")};
          }
        `;
      case "small_tiles":
        return css`
          display: grid !important;
          align-items: flex-start;
          flex-direction: column;
          grid-template-columns: repeat(2, 1fr);
          justify-items: flex-start;
          row-gap: 0.7rem;
          cursor: pointer;
          position: relative;
          left: -0.2rem;
          flex: 1;
          margin-left: 0.4rem;
          // margin-right: 1rem;
          & div.param-item:nth-child(2) {
            right: 0.8em;
          }
        `;
      default:
        return css`
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          cursor: pointer;
          flex: 1;
        `;
    }
  }}
`;

const PatientItemBottom = styled.div`
  z-index: 1;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: whitesmoke;
  padding-left: 15px;
  position: relative;
`;

const BottomItem = styled.div`
  ${(props) => {
    switch (props.alarm) {
      case "news":
        return css`
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          &:hover {
            font-style: italic;
          }
        `;
      default:
        return css`
          display: flex;
          flex-direction: row;
          align-items: center;
          // max-width: 200px;
          // width: 120px;
          cursor: pointer;
          &:hover {
            font-style: italic;
          }
        `;
    }
  }}
`;

const PatientItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  border: ${(props) =>
    window.location.href.indexOf("portal") === -1
      ? normalBorder
      : props.$news2color
      ? `2px solid ${props.$news2color}`
      : props.$maxpriority == undefined || props.$maxpriority == 0
      ? normalBorder
      : `2px solid ${props.$maxprioritycolor};`};
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;

  :hover {
    // opacity: 0.8;
  }
`;

const Wrapper = styled.div``;

const ListPlaceholder = styled.div``;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

// temp_amb
//     :
//     3422
// temp_amb_timestamp
//     :
//     1666332128000

// temp_sk1
//     :
//     37.62
// temp_sk1_timestamp
//     :
//     1666365669000

// temp_sk2
//     :
//     34.22
// temp_sk2_timestamp
//     :
//     1666332128000

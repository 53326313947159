import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import moment, { utc } from "moment";
import styled from "styled-components";
import testData from "./output.json";

const apiTemplate = `https://api.integration-e1.corsano.com/v2/ds/users/{id}/ai-sleep-measurements?from={fromDate}&to={toDate}`;
const apiTemplate2 = `https://api.integration-e1.corsano.com/v2/ds/users/{id}/sleep-measurements-continuous/sleep-data?timestamp_from={fromDate}&timestamp_to={toDate}`;
const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const IS_ASLEEP_HEIGHT = 1;
const IS_AWAKE_HEIGHT = 2;
const IS_AWAKE_COLOR = "#FC0903ff";
const IS_ASLEEP_COLOR = "#3763B5ff";

function findSleepStateChange(sleepData) {
  let changePoints = [];
  if (sleepData.length === 0) {
    return changePoints;
  }
  let lastValue = sleepData[0].is_asleep;
  let lastIndex = 0;

  for (let i = 1; i < sleepData.length; i++) {
    if (sleepData[i].is_asleep !== lastValue) {
      changePoints.push({
        index: i,
        indexFrom: lastIndex,
        indexTo: i,
        is_asleep: sleepData[i - 1].is_asleep,
        dateFrom: sleepData[i - 1].date,
        _dateFrom: moment(sleepData[i - 1].date).format("DD HH:mm Z"),
        dateTo: sleepData[i].date,
      });
      lastIndex = i;
      lastValue = sleepData[i].is_asleep;
    }
  }
  changePoints.push({
    index: sleepData.length - 1,
    indexFrom: lastIndex,
    indexTo: sleepData.length - 1,
    dateFrom: sleepData[sleepData.length - 2].date,
    dateTo: sleepData[sleepData.length - 1].date,
    is_asleep: sleepData[sleepData.length - 1].is_asleep,
  });
  return changePoints;
}

function getTicks(sleepData, ticksNumber) {
  let ticks = Array.from({ length: ticksNumber + 1 }).map((x, i) => ({
    left: (100.0 * i) / ticksNumber,
  }));
  const dataLength = sleepData.length;
  ticks = ticks.map((x) => ({
    ...x,
    date: sleepData[Math.round((x.left * (dataLength - 1)) / 100)],
    t: Date.parse(
      sleepData[Math.round((x.left * (dataLength - 1)) / 100)].date
    ),
  }));

  return ticks;
}

function get24HrTicks(ticksNumber, date) {
  const dateFrom = moment(date);
  let twelveHoursBefore = dateFrom.subtract(12, "hours"); // go back 12 hours to construct ticks

  const interval = Math.round(HOURS_PER_DAY / ticksNumber);

  let ticks = Array.from({ length: ticksNumber + 1 }).map((x, i) => ({
    left: (100.0 * i) / ticksNumber,
  }));

  ticks = ticks.map((x, i) => {
    const tickObj = {
      ...x,
      t: twelveHoursBefore.utc().valueOf(),
      verbal: twelveHoursBefore.format("DD_HH:mm a z"),
    };
    twelveHoursBefore.add(interval, "hours");
    return tickObj;
  });
  return ticks;
}

export default function AiSleepViewTool(props) {
  const { uuid, date } = props;
  const d = new Date();
  let diff = Math.round(d.getTimezoneOffset() / 60);

  const from = +moment(date).clone().subtract(12, "hours");
  const to = +moment(date).clone().add(12, "hours");

  const [sleepData, setSleepData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ticks, setTicks] = useState([]);
  const [sleepStatusChanges, setSleepStatusChanges] = useState([]);
  const ticksNumber = 12;
  const ticks24Hrs = get24HrTicks(ticksNumber, date);
  const [utcOffset, setUtcOffset] = useState(2);
  const [tz, setTz] = useState(0);

  const _boundaryExtend = 12; // add boundary for api
  // console.log("__FROM", from, moment(from).format("dd HH:mm a ZZ"));
  // console.log("__TO", to, moment(to).format("dd HH:mm a ZZ"));

  let _from = moment(from).clone().subtract(_boundaryExtend, "hours");
  let _to = moment(to).clone().add(_boundaryExtend, "hours");

  // console.log("Default offset", diff);

  // console.log(
  //   "__FROM",
  //   _from.valueOf(),
  //   moment(_from).format("DD dd HH:mm a ZZ")
  // );
  // console.log("__TO", _to.valueOf(), moment(_to).format("DD dd HH:mm a ZZ"));
  useEffect(() => {
    setSleepData([]);
    setSleepStatusChanges([]);
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const _api = apiTemplate2
        .replace("{fromDate}", _from.valueOf())
        .replace("{toDate}", _to.valueOf())
        .replace("{id}", uuid);
      // console.log("api:", _api);

      try {
        const response = await fetch(_api);
        const data = await response.json();
        // const data = testData;
        setSleepData(data);
        let testData = data;
        console.log("ai data ", testData);
        console.log("zero", testData[0]);
        console.log(
          moment(testData[0].timestamp).format("YY-M-DD dd HH:mm ZZ zz")
        );
        console.log(moment(testData[0].timestamp).valueOf());

        setTz(moment.parseZone(data[0].date).utcOffset() / 60);
        // console.log("OFFSET", moment.parseZone(data[0].date).utcOffset());

        // console.table(data.slice(0, 3));
        // console.table(data.slice(data.length - 4, data.length - 1));
        // console.log(
        //   "ai data from",
        //   data[0].date,
        //   moment(data[0].date).valueOf()
        // );
        // console.log(
        //   "ai data until",
        //   data[data.length - 1].date,
        //   moment(data[data.length - 1].date).valueOf()
        // );
        setSleepStatusChanges(findSleepStateChange(data));
        setTicks(getTicks(data, ticksNumber));
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [uuid, from, to]);

  const adjustUTCOffset = (d) => {
    let o = d > 0 ? 1 : -1;
    setUtcOffset(utcOffset + o);
  };

  const dateFrom = moment(date);

  // console.log("TZTZ", tz);

  const hourOffset = 12 + tz + diff;

  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");
  // console.table(sleepStatusChanges, ["is_asleep", "dateFrom", "_dateFrom", "dateTo"]);

  let slots24hr = sleepStatusChanges.map((x, i) => {
    return {
      color: x.is_asleep ? IS_ASLEEP_COLOR : IS_AWAKE_COLOR,
      start_date: sleepData[x.indexFrom]?.date,
      end_date: sleepData[x.indexTo]?.date,
      from: Date.parse(sleepData[x.indexFrom]?.date),
      to: Date.parse(sleepData[x.indexTo]?.date),
      height: x?.is_asleep ? IS_ASLEEP_HEIGHT : IS_AWAKE_HEIGHT,
      timestamp: sleepData[x.indexFrom]?.date,
      type: x.is_asleep ? 1 : 0,
      // raw: moment(x.raw).diff(twelveHoursBefore, "minutes") / (24 * 60),
      left_percent:
        (100 *
          moment(sleepData[x.indexFrom]?.date).diff(
            twelveHoursBefore,
            "seconds"
          )) /
        (60 * HOURS_PER_DAY * MINUTES_PER_HOUR),
      width:
        1000 *
        MINUTES_PER_HOUR *
        60 *
        moment(sleepData[x.indexTo]?.date).diff(
          moment(sleepData[x.indexFrom]?.date),
          "seconds"
        ), // in milliseconds
      width_percent:
        (100 *
          moment(sleepData[x.indexTo]?.date).diff(
            moment(sleepData[x.indexFrom]?.date),
            "seconds"
          )) /
        (60 * HOURS_PER_DAY * MINUTES_PER_HOUR),
      // left_percent: (100 * x.indexFrom) / sleepDataLength,
      // width: 1000 * 60 * (x.indexTo - x.indexFrom + 1),
      // width_percent: (100 * (x.indexTo - x.indexFrom + 1)) / sleepDataLength,
    };
  });

  // console.log("binary raw data:", sleepData);

  return (
    <BinarySleepViewTool
      vertAxisLabels={["SLEEP", "AWAKE"]}
      slots={slots24hr}
      ticks={ticks24Hrs}
      utcHandler={adjustUTCOffset}
      utcOffset={utcOffset}
    />
  );
}

function BinarySleepViewTool(props) {
  const {
    slots = [],
    vertAxisLabels = ["Sleep", "Awake"],
    ticksNumber = 8,
    ticks = [],
    noDataText = "...",
    utcHandler,
    loading,
  } = props;

  const optSlots = slots;

  if (optSlots.length == 0) {
    return (
      <NoDataPlaceholder>
        <NoDataText>{noDataText}</NoDataText>
      </NoDataPlaceholder>
    );
  }

  return (
    <ViewWrapper>
      {/* <div>
        <div
          style={{ height: "10px", width: "10px", background: "red" }}
          onClick={() => utcHandler(1)}
        />
        <div
          style={{ height: "10px", width: "10px", background: "blue" }}
          onClick={() => utcHandler(-1)}
        />
        <div>{props.utcOffset}</div>
      </div> */}
      <LeftPlaceholder>
        <LeftInnerPlaceholder>
          {vertAxisLabels.map((x, i) => (
            <VertAxisLabel
              className="sleep-vert-label"
              key={`${x}`}
              height={+i + 1}
            >
              {x}
            </VertAxisLabel>
          ))}
        </LeftInnerPlaceholder>
      </LeftPlaceholder>

      <RightPlaceholder>
        <TopPlaceholder className="sleep-top-placeholder">
          <ChartPlaceholder>
            {[1, 2].map((x) => (
              <HorLine key={x} height={x} />
            ))}
            {optSlots.map((opt, i) => {
              return <Box {...opt} key={`opt_${i}_${opt.width_percent}`}></Box>;
            })}
          </ChartPlaceholder>
        </TopPlaceholder>

        <BottomPlaceholder className="sleep-bottom-placeholder">
          {ticks.map((x, i) => {
            return (
              <Tick key={i} left={x.left}>
                <TickInner className="sleep-tick-label">
                  {moment(x.t).format("DD dd HH:mm")}
                  {/* {moment(x.t).format("DD dd HH:mm ZZ")} */}
                </TickInner>
              </Tick>
            );
          })}
        </BottomPlaceholder>
      </RightPlaceholder>

      {/*{JSON.stringify(optSlots)}*/}
    </ViewWrapper>
  );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = "grey";

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${(props) => `${props.left}%`};
`;

const TickInner = styled.div`
  width: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const ViewWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${(props) => `calc(${(100.0 * props.height) / 2.0}% - 1px)`};
  z-index: 1;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${(props) => `${(100.0 * props.height) / 2.0}%`};
  width: ${(props) => `${props.width_percent}%`};
  left: ${(props) => `${props.left_percent}%`};
  box-sizing: border-box;
  background: ${(props) => props.color};
  z-index: ${(props) => `${props.type}`};
`;

const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${(props) =>
    `calc(${(100.0 * props.height) / 2.0}% - 10px + ${bottomHeight}px)`};
  z-index: 1;
  :last-of-type {
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
`;
const Wrapper = styled.div``;

import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import SimpleTrendsChartTool from "./SimpleTrendsChartTool";
import DoctorAPI from "../../../api/DoctorAPI";

const mergeReadiness = (summaries = []) => {
  let arr = JSON.parse(JSON.stringify(summaries));
  for (let i in arr) {
    let { recovery = {}, stress = {} } = arr[i];
    let date1 = recovery?.date;
    let date2 = stress?.date;
    let date = date1 || date2;
    if (date == undefined) {
      continue;
    }
    if (stress != undefined && recovery != undefined) {
      arr[i].stress.readiness = recovery?.value;
    }
  }
  // console.log("after merging: arr = ", arr);
  return arr;
};

function getFromToFromOption(opt) {
  function getFormatted(t) {
    return moment(+t).format("YYYY-MM-DD");
  }

  let to = +moment().endOf("day");
  let dd = { to: getFormatted(+to) };
  if (opt == "W") {
    dd.from = getFormatted(+moment(to).add(-1, "week"));
  }
  if (opt == "M") {
    dd.from = getFormatted(+moment(to).add(-1, "month"));
  }
  if (opt == "3M") {
    dd.from = getFormatted(+moment(to).add(-3, "month"));
  }
  if (opt == "6M") {
    dd.from = getFormatted(+moment(to).add(-6, "month"));
  }
  if (opt == "MTD") {
    dd.from = getFormatted(+moment(to).startOf("month").startOf("day"));
  }
  if (opt == "YTD") {
    dd.from = getFormatted(+moment(to).startOf("year").startOf("day"));
  }
  if (opt == "1Y") {
    dd.from = getFormatted(+moment(to).add(-12, "month"));
  }
  return dd;
}

export default function TrendsSectionTool(props) {
  const { uuid } = props;
  const [lightSummaryInRange, setLightSummaryInRange] = useState([]);
  const [selectedOption, setSelectedOption] = useState("M");
  const [summaryByType, setSummaryByType] = useState({
    activity: [],
    stress: [],
    heart_rate: [],
    sleep: [],
  });

  const [selectedOptionByType, setSelectedOptionByType] = useState({
    activity: "M",
    stress: "M",
    heart_rate: "M",
    sleep: "M",
  });
  const [loading, setLoading] = useState(false);

  const onChangeSelectedOption = (newOption) => {
    setSelectedOption(newOption);
  };

  const onChangeSelectedOptionByType = (type, newOption) => {
    setSelectedOptionByType((x) => {
      return { ...x, type: newOption };
    });
  };

  useEffect(() => {
    if (uuid == undefined) {
      return;
    }
    const { from, to } = getFromToFromOption(selectedOption);
    // console.log({selectedOption, from, to});
    
    setLoading(true);
    setLightSummaryInRange([]);
    // console.log(from, to);

    DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false).then((d) => {
      setLoading(false);
      // console.log("getUserLightSummariesInRange", d);
      let d_ = mergeReadiness(d);
      // console.log("mergeReadiness = ", d_);
      setLightSummaryInRange(d_);

      let _activityArr = d_.map((x) => ({ date: x.date, ...x["activity"] }));
      let _stressArr = d_.map((x) => ({ date: x.date, ...x["stress"] }));
      let _heartRateArr = d_.map((x) => ({ date: x.date, ...x["heart_rate"] }));
      let _sleepArr = d_.map((x) => ({ date: x.date, ...x["sleep"] }));
      setSummaryByType({
        activity: _activityArr,
        stress: _stressArr,
        heart_rate: _heartRateArr,
        sleep: _sleepArr,
      });
      // let dArr = d_.map((x) => ({ date: x.date, ...x[dataType] }));
      // setSummaries(dArr);
    });
  }, [selectedOption, uuid]);

  // useEffect(() => {
  //   if (uuid == undefined) {
  //     return;
  //   }
  //   const { from, to } = getFromToFromOption(selectedOption);
  //   setLoading(true);
  //   setLightSummaryInRange([]);
  //   DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false).then((d) => {
  //     // console.log("raw summaries = ", d);
  //     setLoading(false);
  //     let d_ = mergeReadiness(d);
  //     let _activityArr = d_.map((x) => ({ date: x.date, ...x["activity"] }));
  //     let _stressArr = d_.map((x) => ({ date: x.date, ...x["stress"] }));
  //     let _heartRateArr = d_.map((x) => ({ date: x.date, ...x["heart_rate"] }));
  //     let _sleepArr = d_.map((x) => ({ date: x.date, ...x["sleep"] }));
  //     setSummaryByType({
  //       activity: _activityArr,
  //       stress: _stressArr,
  //       heart_rate: _heartRateArr,
  //       sleep: _sleepArr,
  //     });
  //     setLightSummaryInRange(d_);
  //     // let dArr = d_.map((x) => ({ date: x.date, ...x[dataType] }));
  //     // setSummaries(dArr);
  //   });
  // }, [selectedOption, selectedOptionByType, uuid]);

  return (
    <Wrapper>
      <GridItem>
        <Heading className="patient-statistics-heading">Activity</Heading>
        <SimpleTrendsChartTool
          {...props}
          dataType={"activity"}
          loading={loading}
          summaries={summaryByType["activity"]}
          selectedOption={selectedOption}
          onChangeSelectedOptionByType={onChangeSelectedOptionByType}
          onChangeSelectedOption={onChangeSelectedOption}
        />
      </GridItem>
      {window.location.href.indexOf("portal.corsano") > -1 ? null : (
        <GridItem>
          <Heading className="patient-statistics-heading">Sleep</Heading>
          <SimpleTrendsChartTool
            {...props}
            dataType={"sleep"}
            loading={loading}
            summaries={summaryByType["sleep"]}
            selectedOption={selectedOption}
            onChangeSelectedOptionByType={onChangeSelectedOptionByType}
            onChangeSelectedOption={onChangeSelectedOption}
          />
        </GridItem>
      )}
      <GridItem>
        <Heading className="patient-statistics-heading">Pulse rate</Heading>
        <SimpleTrendsChartTool
          {...props}
          dataType={"heart_rate"}
          loading={loading}
          summaries={summaryByType["heart_rate"]}
          selectedOption={selectedOption}
          onChangeSelectedOptionByType={onChangeSelectedOptionByType}
          onChangeSelectedOption={onChangeSelectedOption}
        />
      </GridItem>

      {window.location.href.indexOf("portal.corsano") > -1 ? null : (
        <GridItem>
          <Heading className="patient-statistics-heading">HRV</Heading>
          <SimpleTrendsChartTool
            {...props}
            dataType={"stress"}
            loading={loading}
            summaries={summaryByType["stress"]}
            selectedOption={selectedOption}
            onChangeSelectedOptionByType={onChangeSelectedOptionByType}
            onChangeSelectedOption={onChangeSelectedOption}
          />
        </GridItem>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 10px;
`;

const GridItem = styled.div`
  padding: 10px;
`;

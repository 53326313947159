import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DoctorAPI from "../../../api/DoctorAPI";
import moment from 'moment';
import SimpleAfNsrChart from "./SimpleAfNsrChart";
import EcgRecordPanel from "../../ecg/panels/EcgRecordPanel";
import Sidebar from "arui-feather/sidebar";
import NiceModal from "../../modals/NiceModal";
import SimplePdfViewer from "./SimplePdfViewer";


// const NSR_COLOR = "#FF0000" // old
// const AFIB_COLOR = "#0000FF" // old
const NSR_COLOR = "#0000FF" 
const AFIB_COLOR = "#FF0000" 

export default function AfNsrTool(props) {
    let {
        t,
        uuid,
    } = props;
    // /v2/users/:uuid/af-nsr-points
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);
    const [viewId, setViewId] = useState();
    const [selectedAlert, setSelectedAlert] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        setData(undefined);
        let from = +moment(+t).add(-30, 'days').startOf('day');
        let to = +moment(+t).endOf('day');
        DoctorAPI.getAfNsrData(uuid, from, to).then(x => {
            setData(x);
            setLoading(false);
        });
    }, [t]);

    let measurements = data?.measurements || [];
    let alerts = data?.alerts || [];
    let activityAfibNsrPoints = data?.activityAfibNsrPoints || [];
    let afibNsrDataHappitechPoints = data?.afibNsrDataHappitech || [];
    let points = afibNsrDataHappitechPoints.map(x => {
        let isNsr = (x.nsr == true);
        let isAfib = (x.afb == true);
        let color = 'rgba(156,163,175,0.57)';
        if (isNsr == true) {
            color = NSR_COLOR;
        }
        if (isAfib == true) {
            color = AFIB_COLOR;
        }
        return {
            isNsr: isNsr,
            isAfib: isAfib,
            t: x.t,
            value: 1,
            color: color,
            stroke: color
        }
    });

    let afibPoints = points.filter(x => (x.isAfib == true));
    let nsrPoints = points.filter(x => (x.isNsr == true));

    let measurementPoints = measurements.map(x => {
        return {
            ...x,
            t: +x.start_timestamp,
            value: 0,
            color: 'rgba(0,0,0,0.65)',
            // color: '#ffd700',
            stroke: '#030c7c',
            // stroke: '#ffd700',
            isMeasurement: true
        }
    });

    let alertsPoints = alerts.map(x => {
        return {
            ...x,
            t: +x.timestamp,
            color: 'rgba(0,0,0,0.65)',
            value: 2,
            stroke: '#030c7c'
        }
    });

    // console.log('render: alertsPoints = ', alertsPoints);

    let allPoints = [...afibPoints, ...nsrPoints, ...measurementPoints, ...alertsPoints].sort((a, b) => (a.t - b.t));
    // console.log(allPoints);
    

    // let from = +moment(+t).add(-30, 'days').startOf('day');
    let to = +moment(+t).endOf('day');
    let from = allPoints.length == 0 ? +moment(+t).add(-30, 'days').startOf('day') : Math.min(...allPoints.map(x => x.t));
    // let to = allPoints.length == 0 ? +moment(+t).endOf('day') : Math.max(...allPoints.map(x => x.t));
    // let to = t;


    let middle = from + (to - from) / 2;
    let firstHalf = allPoints.filter(x => (x.t < middle));
    let secondHalf = allPoints.filter(x => (x.t > middle));

    // console.log('measurementPoints = ', measurementPoints);

    let viewItem =
        viewId == undefined
            ? undefined
            : measurements.find((x) => x.uuid == viewId);

    if (loading == false && allPoints.length == 0) {
        return (
            <div style={{textAlign: 'center', padding: '10px'}}>
                No data
            </div>
        )
    }

    let file_ids = (selectedAlert?.file_ids || []);

    return (
        <Wrapper>

            {firstHalf.length == 0 ? null :
                <ChartPlaceholder>
                    <SimpleAfNsrChart points={firstHalf}
                                      min={from}
                                      max={middle}
                                      onMeasurementClick={mUuid => {
                                          console.log('onMeasurementClick: mUuid = ', mUuid);
                                          setViewId(mUuid);
                                      }}
                                      onAlertClick={async x => {
                                          let n = (x?.file_ids || []).length;
                                          if (n == 0){
                                              return;
                                          }
                                          setSelectedAlert(x);
                                      }}

                    />
                </ChartPlaceholder>
            }


            {secondHalf.length == 0 ? null :
                <ChartPlaceholder>
                    <SimpleAfNsrChart points={secondHalf}
                                      min={middle}
                                      max={to}
                                      onMeasurementClick={mUuid => {
                                          setViewId(mUuid);
                                      }}
                                      onAlertClick={async x => {
                                          let n = (x?.file_ids || []).length;
                                          if (n == 0){
                                              return;
                                          }
                                          setSelectedAlert(x);
                                      }}
                    />
                </ChartPlaceholder>
            }

            <Sidebar
                visible={viewItem != undefined}
                width={window.innerWidth}
                onCloserClick={() => {
                    setViewId(undefined);
                }}
            >
                {viewItem == undefined ? null : (
                    <div style={{width: "100%"}}>
                        <h2 style={{marginTop: -30}}>
                            {moment(viewItem?.start_timestamp).format("DD.MM.YYYY HH:mm")}
                        </h2>
                        <EcgRecordPanel
                            forceUrl={viewItem?.forceUrl}
                            userUUID={uuid} measurementUUID={viewItem?.uuid}/>
                    </div>
                )}
            </Sidebar>

            {selectedAlert == undefined ? null :
                <NiceModal
                    zIndex={1000000000}
                    onClose={() => {
                    setSelectedAlert(undefined);
                }}>
                    <AlertInnerPlaceholder>

                        <div>
                            <SimplePdfViewer
                                isImage={selectedAlert?.isImage == undefined ? false : selectedAlert?.isImage}
                                uuid={uuid}
                                file_ids={file_ids}
                            />
                        </div>

                    </AlertInnerPlaceholder>
                </NiceModal>
            }

        </Wrapper>
    );
}

const AlertInnerPlaceholder = styled.div`
  padding: 40px;
  width: 600px;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  padding-left: 45px;
  margin-bottom: 110px;
`;

const Wrapper = styled.div`
  padding: 10px;
  //background: lightgrey;
  margin-top: 10px;
  margin-bottom: 10px;
`;
import { useState } from "react";
import styled from "styled-components";
import Spin from "arui-feather/spin";
import SmartTrendsChart from "./SmartTrendsChart";
import ls from "local-storage";

const OPTIONS = [
  {
    label: "WEEK",
    value: "W",
  },
  {
    label: "MONTH",
    value: "M",
  },
  {
    label: "3M",
    value: "3M",
  },
  {
    label: "MTD",
    value: "MTD",
  },
  {
    label: "6M",
    value: "6M",
  },
  {
    label: "YTD",
    value: "YTD",
  },
  {
    label: "1Y",
    value: "1Y",
  },
];

export default function SimpleTrendsChartTool(props) {
  const {
    uuid,
    dataType = "activity",
    chartType = "line",
    summaries = [],
    loading = false,
    selectedOption = "M",
    onChangeSelectedOption = () => {},
    onChangeSelectedOptionByType = () => {},
  } = props;

  // const [summaries, setSummaries] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("M");
  const theme = ls.get("theme");

  // useEffect(() => {
  //   if (uuid == undefined) {
  //     return;
  //   }
  //   const { from, to } = getFromToFromOption(selectedOption);
  //   setLoading(true);
  //   setSummaries([]);
  //   DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false).then((d) => {
  //     console.log("raw summaries = ", d);
  //     let d_ = mergeReadiness(d);
  //     console.log("mergeReadiness = ", d_);
  //     let dArr = d_.map((x) => ({ date: x.date, ...x[dataType] }));
  //     setSummaries(dArr);
  //     setLoading(false);
  //   });
  // }, [selectedOption, uuid]);

  return (
    <Wrapper>
      <TopPlaceholder>
        <SwitcherPlaceholder>
          {OPTIONS.map((a, i) => {
            return (
              <SwitchItem
                selected={selectedOption == a.value}
                key={a.value}
                theme={theme}
                onClick={() => {
                  // setSelectedOption(a.value);
                  onChangeSelectedOption(a.value);
                  onChangeSelectedOptionByType(dataType, a.value);
                }}
              >
                {a.label}
              </SwitchItem>
            );
          })}
        </SwitcherPlaceholder>
      </TopPlaceholder>

      <ChartPlaceholder>
        <ChartInnerPlaceholder style={{ opacity: loading == true ? 0.5 : 1 }}>
          <SmartTrendsChart
            points={summaries}
            // points={summaries.map((x) => ({ date: x.date, ...x[dataType] }))}
            loading={loading}
            dataType={dataType}
          />

          {loading == false ? null : (
            <LoaderPlaceholder>
              <LoaderInnerPlaceholder>
                <Spin visible={true} />
              </LoaderInnerPlaceholder>
            </LoaderPlaceholder>
          )}
        </ChartInnerPlaceholder>
      </ChartPlaceholder>
    </Wrapper>
  );
}

const ChartInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const LoaderPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  z-index: 10;
`;

const LoaderInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const SwitchItem = styled.div`
  border-bottom: ${(props) =>
    props.selected == true ? "1px solid #4aa4e3" : "1px solid transparent"};
  font-weight: ${(props) => (props.selected == true ? "bold" : "default")};
  cursor: ${(props) => (props.selected == true ? "default" : "pointer")};
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "white"
        : "black"
      : props.theme === "dark"
      ? "#ccc"
      : "black"};

  :hover {
    opacity: 0.7;
  }
`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const SwitcherPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

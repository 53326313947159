import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  AXES_COLORX,
  AXES_STROKE_WIDTH,
  DOT_STROKE,
  GRID_DASH_ARRAY,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
  LINE_COLORS,
  XAXIS_COLOR,
  xaxisTimeFormatter,
} from "../ui/templates";
import {
  LineChart,
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../helpers/BaselineHelper";

const Y_DOMAIN = [20, 120];
const WINDOW_SIZE = 5 * 60;
const SPOTS_PER_DAY = 12 * 24;
const MA_SIZE = 3;

const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "baseline",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  // {
  //   value: "adpt",
  //   label: "Adaptive",
  //   color: LINE_COLORS.adaptive,
  //   width: 1,
  //   show: true,
  // },
  // {
  //   value: "gap",
  //   label: "Gap",
  //   show: true,
  //   color: LINE_COLORS.gap,
  //   bstyle: "dashed",
  // },
  // {
];
const fillHrMissingSpot = (data) => {
  if (!data || data.length === 0) {
    return;
  }
  let emptySpots = [];
  for (let i = 0; i < data.length - 1; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let gap = moment(nextPoints).diff(currentPoints, "minutes");
    emptySpots.push(data[i]);
    if (gap > 10) {
      let newSlot = moment(currentPoints).add(1, "minutes").valueOf();
      emptySpots.push({
        bpm: null,
        raw: null,
        t: newSlot,
      });
    } else {
    }
  }
  return emptySpots;
};

const calculateMovingAverageAndAdaptive = (data, maSize, delta, kValue) => {
  /*
  Not using adaptive for now.
*/

  const length = data.length;
  let spotsWithMaAndAdaptive = [];

  for (let i = 0; i < maSize; i++) {
    let adpt = undefined;
    let rawBpm = data[i].bpm;
    if (i === 0) {
      adpt = rawBpm;
    } else {
      adpt =
        rawBpm && data[i - 1].bpm
          ? rawBpm - (delta * rawBpm + kValue * (rawBpm - data[i - 1].bpm))
          : rawBpm;
    }
    spotsWithMaAndAdaptive.push({
      ...data[i],
      ma: data[i].bpm,
      maSpots: [data[i].bpm],
      adpt: adpt,
      dashed: undefined,
    });
  }

  for (let i = maSize; i < length; i++) {
    if (!data[i].bpm) {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        maSpots: [data[i].bpm],
        adpt: null,
        nextToNull: 0,
        dashed: undefined,
      });
      continue;
    }
    let sum = 0;
    let validSpots = 0;
    let spots = [];
    const rawBpm = data[i].bpm;
    // const rawBpm_o = data[i - maSize].bpm ? ;
    let rawBpm_o = data[i - 1].bpm ? data[i - 1].bpm : rawBpm;

    let betweenNulls;
    let betweenNums;
    let dashedValue;
    if (data[i - 1]?.bpm && data[i + 1]?.bpm) {
      betweenNums = true;
    }
    if (data[i - 1]?.bpm === null && data[i + 1]?.bpm === null) {
      betweenNulls = true;
    }

    dashedValue = !betweenNulls && !betweenNums ? rawBpm : undefined;

    for (let j = -(maSize - 1); j <= 0; j++) {
      const _rawBpm = data[i + j].bpm;
      if (_rawBpm) {
        sum += _rawBpm;
        spots.push(_rawBpm);
        validSpots += 1;
      }
    }
    const avg = (sum / validSpots).toFixed(1);

    let rateOfChange = rawBpm - rawBpm_o;
    let adaptiveBpm = avg - (delta * avg + kValue * rateOfChange);
    // console.log("rateOfChange", rawBpm_o, rateOfChange, adaptiveBpm);
    let point = {
      ...data[i],
      ma: avg,
      maSpots: spots,
      adpt: adaptiveBpm.toFixed(1),
      rateOfChange: `${rateOfChange.toFixed(4)}, (${rawBpm_o})`,
    };
    if (dashedValue && dashedValue !== null && dashedValue !== undefined) {
      point["dashed"] = dashedValue - 10;
    } else {
      point["dashed"] = undefined;
    }

    spotsWithMaAndAdaptive.push(point);
  }
  return spotsWithMaAndAdaptive;
};

const calculateAdptFromArray = (start, end, avg, delta, kValue) => {
  /*
   *remove for now
   */
  let rateOfChange = end - start;
  let adaptiveValue = avg - (delta * avg + kValue * rateOfChange);
  adaptiveValue = adaptiveValue.toFixed(1);
  return adaptiveValue;
};

const calculateMovingAverage = (data, window) => {
  const length = data.length;
  let spotsWithMaAndAdaptive = [];
  for (let i = 0; i <= length - 1; i++) {
    const currentBpm = data[i].bpm;
    const prevBpm = i > 0 ? data[i - 1].bpm : currentBpm;
    if (currentBpm) {
      let hasValid = false;
      let t0 = moment(data[i].slot);
      let _arr = [];
      let startInd = i >= 80 ? i - 80 : 0;
      for (let j = startInd; j <= i; j++) {
        let df = t0.diff(moment(data[j].slot), "minutes");
        if (df < window) {
          _arr = data.slice(j, i + 1);
          break;
        }
      }
      // console.log(_arr, currentBpm);

      let firstNonNullBpmItem = _arr.find((item) => item.bpm !== null);
      let firstNonNullBpm;

      if (firstNonNullBpmItem === undefined) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
          test: null,
        });
        continue;
      } else {
        firstNonNullBpm = firstNonNullBpmItem.bpm;
      }

      let _ma = BaselineHelper.calculateMAFromArray(_arr, "bpm");
      let _wma = BaselineHelper.calculateBWMAFromArray(
        _arr,
        "bpm",
        t0,
        currentBpm - prevBpm
      );
      if (_ma === 0) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
          test: null,
        });
        continue;
      }

      let adpt = null;

      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: _ma,
        adpt: adpt,
        test: _wma,
      });
    } else {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
        test: null,
      });
    }
  }
  return spotsWithMaAndAdaptive;
};

const PatientDayPulseRateBaselineTool = (props) => {
  let {
    uuid,
    date,
    hasNoData,
    theme,
    tz = 0,
    baselineWindow = 15,
    points = [],
    loading = false,
  } = props;
  const [ref, { width, height }] = useDimensions();
  // const [loading, setLoading] = useState(false);
  const [hrRawData, setHrRawData] = useState([]);
  const [hrProcessedData, setHrProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    baseline: true,
  });
  const [dataLength, setDataLength] = useState(0);

  // console.log(
  //   "bpm",
  //   points.map((x) => {
  //     return {
  //       bpm: x.bpm,
  //       t: x.t,
  //       date: moment(x.t).format("HH:mm"),
  //     };
  //   })
  // );

  useEffect(() => {
    if (!points || points.length === 0) {
      return;
    }
    // console.table(points, ["date", "bpm_q", "bpm", "baseline"]);
    
    let _points = points.filter(
      (x) => x?.bpm_q 
    );
    _points = fillHrMissingSpot(_points);
    setHrRawData(_points);
    setHrProcessedData(_points);
    setDataLength(_points.length);
  }, [date, uuid, points]);

  let extraLines = DrawHelper.getExtraDottedChartsData(hrProcessedData, "bpm");
  let hrWithGaps = hrProcessedData;

  for (let bpm of hrWithGaps) {
    // console.log("bpm", bpm);
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === bpm.slot) {
        bpm[`bpm_gap_${el}`] = extraLines[el]["points"][0][`bpm_gap_${el}`];
      }
      if (slot2 === bpm.slot) {
        bpm[`bpm_gap_${el}`] = extraLines[el]["points"][1][`bpm_gap_${el}`];
      }
    }
  }

  let xticks = BaselineHelper.getXaxisTicksEachHour(
    moment(date).startOf("day")
  );
  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();

  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Calculating..."}
        showTopImg={false}
      />
    );
  }
  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }

  return (
    <Wrapper ref={ref}>
      <ComposedChart
        width={width}
        height={height}
        data={hrProcessedData}
        // connectNulls={true}
        margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
      >
        <CartesianGrid
          stroke={GRID_STROKE_COLOR}
          strokeWidth={GRID_THICKNESS}
          strokeDasharray={GRID_DASH_ARRAY}
        />

        <XAxis
          // stroke={theme === "dark" ? "white" : AXES_COLOR}
          stroke={AXES_COLORX(theme)}
          strokeWidth={AXES_STROKE_WIDTH}
          tickSize={10}
          interval={0}
          ticks={xticks}
          type="number"
          dataKey={"t"}
          tickCount={xticks.length}
          domain={[startOfDay, startOfNextDay]}
          tickFormatter={xaxisTimeFormatter}
          // tickFormatter={(a) => {
          //   return moment(a).subtract(tz, "hours").format("HH:mm");
          // }}
          allowDataOverflow={true}
        />
        <YAxis
          dataKey={"bpm"}
          stroke={AXES_COLORX(theme)}
          strokeWidth={AXES_STROKE_WIDTH}
          domain={([min, max]) => {
            return [0, max > 120 ? max : 120];
          }}
        />
        <Tooltip content={<HrTooltip />} />
        <Line
          type="monotone"
          dataKey="ma"
          name="Baseline"
          stroke={LINE_COLORS.baseline}
          strokeWidth={2}
          activeDot={true}
          connectNulls={true}
          // hide={showLine["ma"] ? false : true}
          hide={true}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="adpt"
          name="Adaptive"
          stroke={LINE_COLORS.adaptive}
          connectNulls={true}
          // strokeDasharray="5 5"
          hide={true}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="baseline"
          // name="Adaptive"
          stroke={LINE_COLORS.baseline}
          strokeWidth={2}
          connectNulls={true}
          // strokeDasharray="5 5"
          hide={showLine["baseline"] ? false : true}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="bpm"
          name="Raw"
          stroke={LINE_COLORS.raw}
          // dot={DOT_STROKE}
          dot={false}
          isAnimationActive={false}
          connectNulls={false}
          hide={showLine["raw"] ? false : true}
        />
        {extraLines.map((l, i) => {
          // console.log("EXTRALINE ", i, l);
          // console.log(l);

          return (
            <Line
              key={i}
              type={"monotone"}
              dataKey={`bpm_gap_${i}`}
              isAnimationActive={false}
              strokeWidth={2}
              strokeDasharray="3 3"
              stroke="#a0a0af90"
              // hide={showLine["raw"] ? false : true}
              hide={true}
              dot={false}
              // name={"D"}
              legendType="none"
              connectNulls={true}
            />
          );
        })}
        {/* <Legend /> */}
      </ComposedChart>
      <LineFilterContainer>
        {LINES.map((f, i) => {
          return (
            <LineFilterItem key={i}>
              <LineFilterCheckbox
                type="checkbox"
                name={f.value}
                checked={showLine[f.value]}
                onChange={handleLineCheckbox}
              />
              <LineLabel
                color={f.color}
                $bstyle={f.bstyle ? f.bstyle : "solid"}
                theme={theme}
              >
                {f.label}
              </LineLabel>
            </LineFilterItem>
          );
        })}
      </LineFilterContainer>
    </Wrapper>
  );
};

export default PatientDayPulseRateBaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  position: relative;
`;

const HrTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`Raw BPM: ${payload[0].payload.bpm}`}</TooltipRow>
        {/* <TooltipRow className="label">{`Weighted: ${payload[0].payload.ma}`}</TooltipRow> */}
        <TooltipRow className="label">{`Baseline: ${payload[0].payload.baseline}`}</TooltipRow>
        <TooltipRow className="label">{`Q: ${payload[0].payload.bpm_q}`}</TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
};

export const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

export const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

export const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

export const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.color};
    border-style: ${(props) => props.$bstyle};
    // border-top: 1rem solid ${(props) => props.color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
